import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  loginError: boolean = false;
  errorMessage: string = "";
  loginForm: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.minLength(4)]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)])
  });

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
  }

  login() {
    this.sessionService.logIn(this.loginForm.value, { redirect: true }).catch(errMsg => {
      this.loginError = true;
      if (typeof errMsg == "string")
        this.errorMessage = errMsg;
      else
        this.errorMessage = "Ocurrio un error al iniciar sesión";
    });
  }

}
