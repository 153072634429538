import { Component, OnInit, HostBinding } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-theme-picker',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss']
})
export class ThemePickerComponent implements OnInit {

  themes: Array<any> = [
    { name: 'dark-theme', primary: 'black' },
    { name: 'light-theme', primary: 'white' },
  ];
  currentTheme: string = "";

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
  }

  public setTheme(theme: string) {
    this.themeService.changeTheme(theme);
  }

}
