import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'Rxjs';

@Injectable()
export class LoaderService {


  private loader = new BehaviorSubject<boolean>(false);
  events = this.loader.asObservable();

  constructor() { }

  showSpinner() {
    this.loader.next(true);
  }

  hideSpinner() {
    this.loader.next(false);
  }
}