<mat-toolbar style="border-radius:5px;">
  <h2 *ngIf="readOnly">
    <mat-icon class="fixed-icon">share_location</mat-icon> Editar Dispositivo GPS
  </h2>
  <h2 *ngIf="!readOnly">
    <mat-icon class="fixed-icon">share_location</mat-icon> {{form.controls['_id'].value ? 'Modificar Dipositivo GPS' :
    'Nuevo
    Dispositivo GPS'}}
  </h2>
  <span class="fill-remaining-space"></span>
  <button class="btn-dialog-close" mat-button (click)="close()" tabIndex="-1">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>

<br>

<form #userForm [formGroup]="form" (submit)="submit()" class="padding-top padding-right padding-left">

  <input type="hidden" formControlName="_id">

  <div fxLayout="row" fxLayoutGap="1%" fxLayout.xs="column">
    <mat-form-field fxFlex="50%">
      <mat-label>ID*</mat-label>

      <input #firstInput formControlName="deviceId" matInput placeholder="ID Del Dispositivo">
      <mat-error *ngIf="form.controls['deviceId'].errors?.required">Campo necesario</mat-error>
      <mat-error *ngIf="form.controls['deviceId'].errors?.minlength">Minimo 10 caracteres</mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="50%">
      <mat-label>Número Telefónico*</mat-label>

      <input formControlName="phoneNumber" type="number" matInput placeholder="Numero De Telefono Asignado">
      <mat-error *ngIf="form.controls['phoneNumber'].errors?.required">Campo necesario</mat-error>
      <mat-error *ngIf="form.controls['phoneNumber'].errors?.minlength">Minimo 10 caracteres</mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="1%" fxLayout.xs="column">
    <mat-form-field fxFlex="50%">
      <mat-label>Descripción</mat-label>
      <input formControlName="description" matInput placeholder="Descripción Opcional">
    </mat-form-field>
    <div fxFlex="20%" style="padding-top:20px;">
      <mat-checkbox formControlName="active" matInput color="primary">Activo</mat-checkbox>
    </div>
  </div>

  <div class="padding-top">
    <mat-divider></mat-divider>
  </div>

  <div class="padding-top" fxLayout="row" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="end">

    <button type="submit" mat-raised-button color="primary" fxFlex="20%" fxFlex.xs="100%"
      [disabled]="form.invalid">Guardar</button>

    <button type="button" (click)="clearForm()" fxFlex="20%" mat-raised-button fxFlex.xs="100%"
      color="warn">Limpiar</button>

  </div>
</form>