import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseService } from './base.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class BusService extends BaseService {
  // busList : Array<


  constructor(
    private http_: HttpClient,
    private sessionService: SessionService
  ) {
    super(http_);
    this.url = 'bus';
  }

  //Posts form value
  post(form?: any): Promise<any> {
    form.user = this.sessionService.currentUser;

    return new Promise((resolve, reject) => {
      super.post(form)
        .then(data => {
          resolve(data);
        }).catch(err => {
          reject(err);
        });
    });
  }

  //Updates form value
  update(form?: any): Promise<any> {
    form.user = this.sessionService.currentUser;

    return new Promise((resolve, reject) => {
      super.put(form)
        .then(data => {
          resolve(data);
        }).catch(err => {
          reject(err);
        });
    })
  }

  loadRouteHistory(busId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      super.custom('GetRouteAssignmentHistory', { busId: busId }).then((data) => {
        resolve(data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  loadDeviceHistory(busId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      super.custom('GetDeviceAssignmentHistory', { busId: busId }).then((data) => {
        resolve(data);
      }).catch(err => {
        reject(err);
      });
    });
  }

}
