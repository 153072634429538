import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
// import { MatSnackBar, SimpleSnackBar, MatSnackBarRef, MatSnackBarConfig } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) { }

  //Standard configuration object for snackbar components
  private baseConfig: MatSnackBarConfig = {
    duration: 3000,
    verticalPosition: 'bottom',
    horizontalPosition: 'end'
  }

  showConfirmation(text: string): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(text, "Ok");
  }

  //Normal basic snackbar
  show(text: string): MatSnackBarRef<SimpleSnackBar>;
  show(text: string, duration: number): MatSnackBarRef<SimpleSnackBar>;
  show(text: string, duration?: number): MatSnackBarRef<SimpleSnackBar> {
    let config = this.baseConfig;

    config.panelClass = [];

    if (duration != null)
      config.duration = duration;

    return this.snackBar.open(text, '', config);
  }

  //Success snackbar
  showSuccess(text: string): MatSnackBarRef<SimpleSnackBar>;
  showSuccess(text: string, action: string): MatSnackBarRef<SimpleSnackBar>;
  showSuccess(text: string, action: string, duration: number): MatSnackBarRef<SimpleSnackBar>;
  showSuccess(text: string, action?: string, duration?: number): MatSnackBarRef<SimpleSnackBar> {
    let config = this.baseConfig;

    config.panelClass = ['notification', 'success'];
    if (action == null)
      action = '';

    if (duration != null)
      config.duration = duration;

    return this.snackBar.open(text, action, config);
  }

  //Warning snackbar
  showWarn(text: string): MatSnackBarRef<SimpleSnackBar>;
  showWarn(text: string, action: string): MatSnackBarRef<SimpleSnackBar>;
  showWarn(text: string, action: string, duration: number): MatSnackBarRef<SimpleSnackBar>;
  showWarn(text: string, action?: string, duration?: number): MatSnackBarRef<SimpleSnackBar> {
    let config = this.baseConfig;

    config.panelClass = ['notification', 'warning'];
    if (action == null)
      action = '';

    if (duration != null)
      config.duration = duration;

    return this.snackBar.open(text, action, config);
  }

}
