import { Component, ElementRef, EventEmitter, Inject, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { PermissionService } from 'src/app/services/permission.service';
import { UserSecurityComponent } from '../user-security/user-security.component';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

  @ViewChild('firstInput') firstInput: ElementRef;
  @ViewChild(UserSecurityComponent) userSecurityComponent: UserSecurityComponent;
  @Output() closedForm = new EventEmitter<any>();
  @Output() dataSubmitted = new EventEmitter<any>();

  readOnly: boolean = false;
  userPermissions: Array<any> = [];
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    username: new FormControl('', [Validators.required, Validators.minLength(4)]),
    name: new FormControl('',),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    isAdmin: new FormControl(false),
    active: new FormControl(false)
  });

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    public userService: UserService,
    private notification: NotificationService,
    private loader: LoaderService,
    private permissionService: PermissionService,
    @Optional() private dialogRef: MatDialogRef<UserFormComponent>
  ) { }

  ngOnInit(): void {
  }

  initializeForm() {
    this.form.setValue({
      _id: null,
      username: '',
      name: '',
      email: '',
      password: '',
      isAdmin: false,
      active: false
    });
    this.userPermissions = [];
  }

  populateForm(user: any) {
    this.form.patchValue(user);
    this.getSecurityData(user.permissions);
  }

  clearForm() {
    this.form.reset();
  }

  submit() {
    if (!this.form.valid)
      return this.notification.showWarn("Formulario invalido", "Ok", 3000);

    this.loader.showSpinner();

    let body = this.form.value;
    body.permissions = this.userSecurityComponent.getPermissions();

    if (this.form.get('_id').value == "" || this.form.get('_id').value == null) {
      this.userService.post(this.form.value).then((result: any) => {
        this.dataSubmitted.emit(true);
        this.form.patchValue({ _id: result._id });
        // this.clearForm();
      }).catch(err => {
        this.notification.showWarn(err.message || "Ocurrio un error al registrar el nuevo usuario");
      }).finally(() => this.loader.hideSpinner());
    }
    else {
      this.userService.put(this.form.value).then(() => {
        this.dataSubmitted.emit(true);
      }).catch(err => {
        this.notification.showWarn(err.message || "Ocurrio un error al guardar el usuario");
      }).finally(() => this.loader.hideSpinner());
    }

  }

  getSecurityData(userPermissions: Array<any>) {
    this.permissionService.get().then(data => {
      //parse user permissions and assign pending permissions
      if (!userPermissions?.length)
        userPermissions = [];
      data.forEach(p => {
        if (!userPermissions.filter(up => p._id == up.permission._id).length)
          userPermissions.push({ permission: p, allowed: false });
      });
      this.userPermissions = userPermissions;
    }).catch(err => {
      console.error(err);
    });
  }

  enableForm() {
    this.readOnly = false;
    this.form.enable();
    this.firstInput.nativeElement.focus();
  }

  disableForm() {
    this.readOnly = true;
  }

  close() {
    let closingData = Object.assign(this.form.value, this.form.value);

    if (this.dialogRef != null)
      this.dialogRef.close(closingData);

    this.closedForm.emit(true);
    this.form.reset();
    this.initializeForm();
  }

}