import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate, CanActivateChild {

  constructor(
    private sessionService: SessionService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    if (!this.sessionExists()) {// si hay sesion
      this.redirectTo('/login');
      return false;
    }

    if (url == '/usuarios') {
      if (this.sessionService.currentUser.isAdmin)
        return true;

      this.redirectTo('/sinAcceso');
      return false;
    }

    if (!this.checkPermissionForScreen(url, this.sessionService.currentUser.permissions)) {
      this.redirectTo('/sinAcceso');
      return false;
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkPermissionForScreen(url: string, userPermissions: Array<any>): boolean {
    if (!userPermissions)
      userPermissions = [];

    return userPermissions.filter(up => up.allowed && up.permission.path == url).length > 0;
  }

  /** Chceks if the session service has an active session */
  sessionExists(): boolean {
    return this.sessionService.isLoggedIn || this.sessionService.recoverSession();
  }

  redirectTo(path: string) {
    this.router.navigate([path]);
    return false;
  }
}
