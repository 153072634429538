<mat-form-field appearance="{{ appearance }}">
  <mat-label>{{ lookupData.label }}</mat-label>
  <input
    matInput
    type="text"
    [placeholder]="lookupData.placeHolder"
    readonly
    [value]="lookupData.description"
  />
  <button
    fxFlex="60px"
    class="search"
    color="accent"
    mat-raised-button
    type="button"
    (click)="openSearchList()"
  >
    <mat-icon>search</mat-icon>
  </button>
  <button
    *ngIf="showClearBtn"
    fxFlex="60px"
    class="clear"
    mat-raised-button
    type="button"
    (click)="clear()"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-form-field>
