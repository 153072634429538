<div class="mat-elevation-z8">
  <mat-card>
    <div fxLayout="row">
      <div fxFlex="30%">
        <!-- <mat-card-title>{{_selectedReport.title}}</mat-card-title> -->
        <mat-card-title>
          <mat-icon class="fixed-icon">report</mat-icon>
          Reporte
        </mat-card-title>
        <mat-card-subtitle>{{_selectedReport.date | formatDate : true}}</mat-card-subtitle>
      </div>
      <div fxFlex="70%">
        <mat-chip-list style="float:right;padding-bottom:10px;">
          <mat-chip *ngIf="_selectedReport.ticketNumber?.length" color="primary">Boleto
            #{{_selectedReport.ticketNumber}}</mat-chip>
          <mat-chip *ngIf="_selectedReport.bus != null && _selectedReport.route != null" color="primary">Autobus
            #{{_selectedReport.bus.number}} en ruta '{{_selectedReport.route.name}}'</mat-chip>
          <mat-chip *ngIf="_selectedReport.bus != null && _selectedReport.route == null" color="primary">Autobus
            #{{_selectedReport.bus.number}}, ruta no asignada</mat-chip>
          <mat-chip *ngIf="_selectedReport.bus == null && _selectedReport.route == null" color="primary">Autobus y ruta
            no registrados</mat-chip>
          <mat-chip *ngIf="_selectedReport.contactPhoneNumber != null">
            Telefono: {{_selectedReport.contactPhoneNumber}}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>

    <div *ngIf="_selectedReport.imgName" class="text-center" style="background:#111;">
      <a href='{{imagesURL + "public/reports/" + _selectedReport.imgName}}' target="_blank">
        <img mat-card-image style="max-height: 500px;max-width:500px;margin:0"
          src='{{imagesURL + "public/reports/" + _selectedReport.imgName}}'>
      </a>
    </div>
    <br>
    <div class="response-div darker-background">
      {{_selectedReport.description}}
    </div>

    <form *ngIf="_selectedReport.response == null" #responseForm [formGroup]="form" (submit)="respondReport()"
      class="response-form">

      <mat-form-field class="full-width">
        <mat-label>Respuesta</mat-label>
        <textarea matInput [disabled]="_selectedReport._id == null || _selectedReport._id == ''"
          formControlName="response" placeholder="Escriba una respuesta"></textarea>
        <mat-error *ngIf="form.controls['response'].errors?.required">Campo necesario</mat-error>
        <mat-error *ngIf="form.controls['response'].errors?.minlength">Minimo 5 caracteres para responder
        </mat-error>
      </mat-form-field>

      <mat-card-actions class="text-right">
        <button color="primary" [disabled]="_selectedReport._id == null || _selectedReport._id == ''"
          mat-raised-button>Responder</button>
      </mat-card-actions>
    </form>

  </mat-card>
</div>

<br>
<div class="mat-elevation-z8">
  <mat-card *ngIf="_selectedReport.response != null">
    <mat-card-title>Respuesta</mat-card-title>
    <mat-card-subtitle>{{_selectedReport.response.date | formatDate : true}}</mat-card-subtitle>
    <div class="response-div darker-background" *ngIf="_selectedReport.response != null">
      {{_selectedReport.response.text}}
    </div>
  </mat-card>
</div>