import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() label: string = 'Seleccione archivo de rol de rutas';
  @Input() confirmButtonText: string = 'Aceptar';
  @Input() cancelButtonText: string = 'Cancelar';
  @Input() description: string = '';
  file: any = null;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData,
    @Optional() private dialogRef: MatDialogRef<FileUploadComponent>
  ) { }

  ngOnInit(): void {
    this.label = this.dialogData?.label || "";
    this.description = this.dialogData?.description || "";
    this.confirmButtonText = this.dialogData?.confirmButtonText || "";
    this.cancelButtonText = this.dialogData?.cancelButtonText || "";
  }

  fileChanged(data) {
    if (data.file == null)
      this.file == null
    else
      this.file = data.file;
  }

  getBody(): FormData {
    let body = new FormData();
    body.append('rolRutas', this.file, this.file.name);
    return body;
  }

  submit() {
    if (this.dialogData.submmit)
      this.dialogData.submmit({ file: this.file });
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close({ file: null });
  }
}
