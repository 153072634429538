<mat-toolbar style="border-radius: 5px">
  <h2 *ngIf="readOnly"><mat-icon>directions_bus</mat-icon> Edit Vehicle</h2>
  <h2 *ngIf="!readOnly">
    <mat-icon>directions_bus</mat-icon>
    {{ form.controls["_id"].value ? "Modify Vehicle" : "New Vehicle" }}
  </h2>
  <span class="fill-remaining-space"></span>
  <button class="btn-dialog-close" mat-button (click)="close()" tabIndex="-1">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>

<br />

<form
  #busForm
  [formGroup]="form"
  (submit)="submit()"
  class="padding-top padding-right padding-left"
>
  <input type="hidden" formControlName="_id" />
  <input type="hidden" formControlName="route" />
  <input type="hidden" formControlName="device" />

  <div fxLayout="row" fxLayoutGap="1%" fxLayout.xs="column">
    <mat-form-field fxFlex="50%">
      <mat-label>Plates*</mat-label>
      <!-- <mat-icon matPrefix>directions_bus</mat-icon> -->
      <input
        #firstInput
        formControlName="name"
        matInput
        placeholder="#000000"
        [disabled]="readOnly"
      />
      <mat-error *ngIf="form.controls['name'].errors?.required"
        >Required field</mat-error
      >
      <mat-error *ngIf="form.controls['name'].errors?.minlength"
        >Minimum of 6 characters</mat-error
      >
    </mat-form-field>

    <mat-form-field fxFlex="25%">
      <mat-label>Code</mat-label>
      <!-- <mat-icon matPrefix>hash</mat-icon> -->
      <input
        formControlName="number"
        matInput
        placeholder="#000000"
        [disabled]="readOnly"
      />
      <mat-error *ngIf="form.controls['number'].errors?.required"
        >Required field</mat-error
      >
      <mat-error *ngIf="form.controls['number'].errors?.minlength"
        >Minimum of 1 character</mat-error
      >
    </mat-form-field>

    <div fxFlex="25%" style="padding-top: 20px">
      <mat-checkbox formControlName="active" matInput color="primary"
        >Active</mat-checkbox
      >
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="1%" fxLayout.xs="column">
    <div fxLayout="column" fxFlex="50%">
      <div fxFlex="100%">
        <app-lookup
          [lookupData]="routeLookupData"
          [showClearBtn]="true"
          (onChange)="routeValueChanged($event)"
        >
        </app-lookup>
        <app-route-history
          [routeHistoryData]="routeHistory"
        ></app-route-history>
      </div>
    </div>

    <div fxLayout="column" fxFlex="50%">
      <div fxFlex="100%">
        <app-lookup
          [lookupData]="deviceLookupData"
          [showClearBtn]="true"
          (onChange)="deviceValueChanged($event)"
        >
        </app-lookup>
        <app-device-history
          [deviceHistoryData]="deviceHistory"
        ></app-device-history>
      </div>
    </div>
  </div>

  <div class="padding-top">
    <mat-divider></mat-divider>
  </div>

  <div
    class="padding-top"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutGap="1%"
    fxLayoutAlign="end"
  >
    <button
      type="submit"
      *ngIf="!readOnly"
      mat-raised-button
      color="primary"
      fxFlex="20%"
      fxFlex.xs="100%"
      [disabled]="form.invalid || readOnly"
    >
      Save
    </button>

    <button
      type="button"
      *ngIf="readOnly && form.controls['_id'].value != null"
      (click)="enableForm()"
      fxFlex="20%"
      mat-raised-button
      fxFlex.xs="100%"
      color="accent"
    >
      Save
    </button>

    <button
      type="button"
      (click)="clearForm()"
      fxFlex="20%"
      mat-raised-button
      fxFlex.xs="100%"
      [disabled]="readOnly"
      color="warn"
    >
      Clear
    </button>
  </div>
</form>
