<div class=mat-elevation-z8>
  <mat-card>
    <mat-toolbar style="border-radius:5px;">
      <h2 *ngIf="readOnly">
        <mat-icon class="fixed-icon">collections</mat-icon> Formulario De Publicidad
      </h2>
      <h2 *ngIf="!readOnly">
        <mat-icon class="fixed-icon">collections</mat-icon> {{form.controls['_id'].value ? 'Modificar Publicidad' :
        'Nueva Publicidad'}}
      </h2>
      <!-- <span class="fill-remaining-space"></span> -->
      <!-- <button class="btn-dialog-close" mat-button (click)="close()" tabIndex="-1">
        <mat-icon>clear</mat-icon>
      </button> -->
    </mat-toolbar>

    <br>

    <form #publicityForm [formGroup]="form" (submit)="submit()" class="padding-top padding-right padding-left">

      <input type="hidden" formControlName="_id">

      <div fxLayout="row" fxLayoutGap="1%" fxLayout.xs="column">
        <mat-form-field fxFlex="50%">
          <mat-label>Titulo</mat-label>

          <input #firstInput formControlName="title" matInput placeholder="Titulo">
          <mat-error *ngIf="form.controls['title'].errors?.required">Campo necesario</mat-error>
          <mat-error *ngIf="form.controls['title'].errors?.minlength">Minimo 5 caracteres</mat-error>
        </mat-form-field>
        <div fxFlex="15%" style="padding-top:20px;">
          <mat-checkbox formControlName="active" matInput color="primary">Activo</mat-checkbox>
        </div>
        <div fxFlex="35%" style="padding-top:20px;">
          <mat-checkbox formControlName="allRoutes" matInput color="primary">Mostrar En Todas Las Rutas</mat-checkbox>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="1%">
        <div fxLayout="column" fxFlex="50%">
          <div fxLayout="row">
            <mat-form-field class="full-width">
              <mat-label>Descripción</mat-label>
              <textarea matInput formControlName="description" placeholder="Escriba una descripción"></textarea>
              <mat-error *ngIf="form.controls['description'].errors?.required">Campo necesario</mat-error>
              <mat-error *ngIf="form.controls['description'].errors?.minlength">Minimo 5 caracteres para descripción
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <app-file-input class="full-width" (fileChanged)="imgChanged($event)" [disabled]="readOnly">
            </app-file-input>
          </div>
          <div fxLayout="row">
            <div fxFlex="100%">
              <app-lookup [lookupData]="routeLookupData" (onChange)="routeValueChanged($event)"></app-lookup>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50%">
          <div fxLayout="row" fxLayoutGap="1%" fxLayout.xs="column">
            <div *ngIf="imgName" class="text-center img-container">
              <a href='{{ imagesURL + "public/publicity/" + imgName}}' target="_blank">
                <img mat-card-image style="max-width:300px;margin:0"
                  src='{{ imagesURL + "public/publicity/" + imgName}}'>
              </a>
            </div>
            <div *ngIf="!imgName" class="img-fill-box darker-background full-width">
              <span *ngIf="file == null">Imagen no cargada</span>
              <span *ngIf="file != null">
                <mat-icon>check</mat-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <mat-label class="margin-bottom">Rutas Asignadas</mat-label>
      </div>
      <div fxLayout="row">
        <mat-chip-list #routesList aria-label="Rutas asignadas para la publicidad">
          <mat-chip *ngFor="let route of routes" (removed)="removeRoute(route)">
            {{route.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>

      <br>
      <!-- <div fxLayout="row" fxFlex="100%"> -->
      <app-activity-history [activityHistoryData]="activity"></app-activity-history>
      <!-- </div> -->

      <div class="padding-top">
        <mat-divider></mat-divider>
      </div>

      <div class="padding-top" fxLayout="row" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="end">

        <button type="submit" mat-raised-button color="primary" fxFlex="20%" fxFlex.xs="100%"
          [disabled]="form.invalid">Guardar</button>

        <button type="button" (click)="clearForm()" fxFlex="20%" mat-raised-button fxFlex.xs="100%"
          color="warn">Limpiar</button>

      </div>
    </form>
  </mat-card>
</div>

<!--  -->