import { Component, ElementRef, EventEmitter, Inject, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RouteService } from 'src/app/services/route.service';
import * as MapBoxGL from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { MapService } from 'src/app/services/map.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-route-form',
  templateUrl: './route-form.component.html',
  styleUrls: ['./route-form.component.scss']
})
export class RouteFormComponent implements OnInit {

  map: MapBoxGL.Map;
  wayPoints: Array<MapBoxGL.Marker> = [];
  places: Array<string> = [];
  routeLength: number = 0;
  separatorKeyCodes: any = [ENTER, COMMA];

  mapLoaded = new EventEmitter<any>();

  @ViewChild('firstInput') firstInput: ElementRef;
  @Output() closedForm = new EventEmitter<any>();
  @Output() formSubmitted = new EventEmitter<any>();

  readOnly: boolean = false;

  constructor(
    public routeService: RouteService,
    private notification: NotificationService,
    private loader: LoaderService,
    private mapService: MapService,
    @Optional() private dialogRef: MatDialogRef<RouteFormComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.initializeMap();
  }

  clearForm() {
    this.routeService.form.reset();
    this.routeService.initializeForm();
  }

  submit() {
    if (!this.routeService.form.valid)
      return this.notification.showWarn("Formulario invalido", "Ok", 3000);

    this.loader.showSpinner();

    let routeData = this.routeService.form.value;
    routeData.wayPoints = this.wayPoints.map(w => w.getLngLat());
    routeData.routeLength = this.routeLength | 0;
    routeData.places = this.places;

    if (routeData._id == "" || routeData._id == null) {
      this.routeService.post(routeData).then((result: any) => {
        this.formSubmitted.emit(true);
        this.notification.showSuccess("Ruta guardada correctamente");
      })
        .catch(err => this.notification.showWarn(err.message || "Ocurrio un error al registrar la nueva ruta"))
        .finally(() => this.loader.hideSpinner());
    }
    else {
      this.routeService.update(routeData).then(() => {
        this.formSubmitted.emit(true);
        this.notification.showSuccess("Ruta guardada correctamente");
      })
        .catch(err => this.notification.showWarn(err.message || "Ocurrio un error al guardar cambios en la ruta"))
        .finally(() => this.loader.hideSpinner());
    }

  }

  enableForm() {
    this.readOnly = false;
    this.routeService.form.enable();
    this.firstInput.nativeElement.focus();
  }

  disableForm() {
    this.readOnly = true;
  }

  close() {
    let closingData = Object.assign(this.routeService.form.value, this.routeService.form.value);

    if (this.dialogRef != null)
      this.dialogRef.close(closingData);

    this.closedForm.emit(true);
    this.routeService.form.reset();
    this.routeService.initializeForm();
  }

  initializeMap() {
    (MapBoxGL as any).accessToken = environment.mapBoxKey;

    this.map = new MapBoxGL.Map({
      container: 'map-wrapper', // container id
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [environment.mapCenter.lng, environment.mapCenter.lat], // starting position
      zoom: 14.2 // starting zoom
    });

    this.map.on('load', () => {
      this.initializeRoute();
      this.mapLoaded.emit(true);
    })

    this.map.on('click', (e) => {
      if (this.wayPoints.filter(w => w.getPopup().isOpen()).length)
        return;
      this.addWayPoint(e.lngLat.lat, e.lngLat.lng);
    });
  }

  checkOutOfRoute(lngLat: any) {
    let parsedWayPoints = this.wayPoints.map(wp => wp.getLngLat());
    if (!this.mapService.isInsideRoute(lngLat, parsedWayPoints))
      this.mapService.addOutOfRouteMarker(this.map, lngLat);
  }

  addWayPoint(lat: number, lng: number) {
    let newWayPoint = this.createMarker(lat, lng);
    this.wayPoints.push(newWayPoint);

    this.updateRoute();
  }

  initializeRoute() {
    this.map.addSource('route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: this.wayPoints.map(w => [w.getLngLat().lng, w.getLngLat().lat])
        }
      }
    });

    this.map.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#888',
        'line-width': 8
      }
    });
  }

  updateRoute() {
    this.printRoute();
    this.calculateRouteLength();
  }

  calculateRouteLength() {
    this.routeLength = this.mapService.calculateRouteDistance(this.wayPoints);
  }

  printRoute() {
    let data: any = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: this.wayPoints.map(w => [w.getLngLat().lng, w.getLngLat().lat])
      }
    };

    (this.map.getSource('route') as MapBoxGL.GeoJSONSource).setData(data);
  }

  createMarker(lat: number, lng: number): MapBoxGL.Marker {
    let el = document.createElement('div');
    el.className = 'waypoint-marker';
    let innerEl = document.createElement('span');
    innerEl.className = 'inner-waypoint-marker';
    el.appendChild(innerEl);
    var marker = new MapBoxGL.Marker(el, {
      draggable: true
    })
      .setLngLat([lng, lat])
      .addTo(this.map);
    marker.setPopup(this.getMarkerPopup());
    marker.on('drag', () => {
      this.updateRoute();
    });

    el.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });

    el.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      e.stopPropagation();

      this.closeAllPopups();
      if (!marker.getPopup().isOpen()) {
        marker.togglePopup();
        console.log(marker.getPopup().getElement());
        this.setPopupActions();
      }
    });

    return marker;
  }

  setPopupActions() {
    let addAfter = document.getElementsByClassName("contextmenu-option addAfter")[0];
    let addBefore = document.getElementsByClassName("contextmenu-option addBefore")[0];
    let del = document.getElementsByClassName("contextmenu-option delete")[0];
    addAfter.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.addMarkerAfter();
    });
    addBefore.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.addMarkerBefore();
    });
    del.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.deleteMarker();
    });
  }


  closeAllPopups() {
    this.wayPoints.forEach(w => {
      if (w.getPopup().isOpen())
        w.togglePopup();
    });
  }

  getMarkerPopup() {
    let popupHTML = `
      <div class="contextmenu-option addAfter">Añadir punto despues</div>
      <div class="contextmenu-option addBefore">Añadir punto antes</div>
      <div class="contextmenu-option delete">Eliminar</div>`;
    let popup = new MapBoxGL.Popup({ closeOnClick: true, closeButton: true }).setOffset([0, -10]).setHTML(popupHTML);
    return popup;
  }

  clearRoute() {
    this.wayPoints.forEach((w: MapBoxGL.Marker) => {
      w.remove();
    });
    this.wayPoints = [];
    this.updateRoute();
  }

  setRouteWayPoints(newWayPoints: Array<any>) {
    this.clearRoute();
    newWayPoints.forEach((w, i) => {
      this.addWayPoint(w.lat, w.lng);
    });

    this.map.flyTo({ center: this.mapService.findRouteCenter(this.wayPoints) });
  }

  addPlace(event: any): void {
    // event.preventDefault();
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.places.push(value.trim());
    }
    console.log(this.places);
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removePlace(place: string): void {
    const index = this.places.indexOf(place);

    if (index >= 0) {
      this.places.splice(index, 1);
    }
  }

  getOpenedMarkerIndex(): number {
    let markerIndex = 0;
    for (let i = 0; i < this.wayPoints.length; i++) {
      if (this.wayPoints[i].getPopup().isOpen()) {
        markerIndex = i;
        break;
      }
    }
    return markerIndex;
  }

  addMarkerAfter() {
    let i = this.getOpenedMarkerIndex();
    this.closeAllPopups();
    if (i >= this.wayPoints.length - 1)
      return;
    let middlePoint = this.mapService.getMiddlePoint(this.wayPoints[i], this.wayPoints[i + 1]);
    let newWayPoint = this.createMarker(middlePoint.lat, middlePoint.lng);
    this.wayPoints.splice(i + 1, 0, newWayPoint);
    this.updateRoute();
  }

  addMarkerBefore() {
    let i = this.getOpenedMarkerIndex();
    this.closeAllPopups();
    if (i <= 0)
      return;
    let middlePoint = this.mapService.getMiddlePoint(this.wayPoints[i], this.wayPoints[i - 1]);
    let newWayPoint = this.createMarker(middlePoint.lat, middlePoint.lng);
    this.wayPoints.splice(i, 0, newWayPoint);
    this.updateRoute();
  }

  deleteMarker() {
    let i = this.getOpenedMarkerIndex();
    this.closeAllPopups();
    this.wayPoints[i].remove();
    this.wayPoints.splice(i, 1);
    this.updateRoute();
  }

}