import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoryService extends BaseService {
  constructor(
    private http_: HttpClient
  ) {
    super(http_);
    this.url = 'history';
  }


  clearHistory() {
    return new Promise((resolve, reject) => {
      this.http_.request(
        'delete',
        environment.serverUrl + this.url + '/clear',
        { body: { date: new Date() } })
        .subscribe(
          (data) => resolve(data),
          (httpError: any) => { reject(httpError.error); })
    });
  }
}
