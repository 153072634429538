<mat-toolbar style="border-radius: 5px">
  <h2 *ngIf="readOnly"><mat-icon>timeline</mat-icon> Editar Ruta</h2>
  <h2 *ngIf="!readOnly">
    <mat-icon>timeline</mat-icon>
    {{
      routeService.form.controls["_id"].value
        ? "Modificar Ruta"
        : "Nueva
    Ruta"
    }}
  </h2>
  <span class="fill-remaining-space"></span>
  <button class="btn-dialog-close" mat-button (click)="close()" tabIndex="-1">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>

<br />

<form
  #routeForm
  [formGroup]="routeService.form"
  (submit)="submit()"
  class="padding-top padding-right padding-left"
>
  <input type="hidden" formControlName="_id" />

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
    <div fxLayout="column" fxFlex="30%">
      <div fxLayout="row">
        <mat-form-field fxFlex="100%">
          <mat-label>Name*</mat-label>
          <!-- <mat-icon matPrefix>timeline</mat-icon> -->
          <input
            #firstInput
            formControlName="name"
            matInput
            placeholder="Route name..."
            [disabled]="readOnly"
          />
          <mat-error *ngIf="routeService.form.controls['name'].errors?.required"
            >Required field</mat-error
          >
          <mat-error
            *ngIf="routeService.form.controls['name'].errors?.minlength"
            >Minimum of 6 characters</mat-error
          >
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field fxFlex="100%">
          <mat-label>Places in Route</mat-label>

          <mat-chip-list
            #placesList
            aria-label="Lugares o cosas que se encuentran a lo largo de la ruta"
          >
            <mat-chip
              *ngFor="let place of places"
              (removed)="removePlace(place)"
            >
              {{ place }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="Places in route..."
              [matChipInputFor]="placesList"
              [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
              (matChipInputTokenEnd)="addPlace($event)"
            />
          </mat-chip-list>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between">
        <mat-label
          >Total Calculated Distance:
          {{ routeLength | number : "1.2-2" }} km</mat-label
        >
        <mat-checkbox formControlName="active" matInput color="primary"
          >Active</mat-checkbox
        >
      </div>
    </div>

    <div fxLayout="column" fxFlex="70%">
      <div fxLayoutAlign="end">
        <button
          mat-raised-button
          type="button"
          color="primary"
          (click)="clearRoute()"
        >
          Clear Route Points
        </button>
      </div>
      <br />
      <div class="map-box-container">
        <div id="map-wrapper" class="mat-elevation-z8"></div>
      </div>
    </div>
  </div>

  <div class="padding-top">
    <mat-divider></mat-divider>
  </div>

  <div
    class="padding-top"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutGap="1%"
    fxLayoutAlign="end"
  >
    <button
      type="submit"
      *ngIf="!readOnly"
      mat-raised-button
      color="primary"
      fxFlex="20%"
      fxFlex.xs="100%"
      [disabled]="routeService.form.invalid || readOnly"
    >
      Save
    </button>

    <button
      type="button"
      *ngIf="readOnly && routeService.form.controls['_id'].value != null"
      (click)="enableForm()"
      fxFlex="20%"
      mat-raised-button
      fxFlex.xs="100%"
      color="accent"
    >
      Save
    </button>

    <button
      type="button"
      (click)="clearForm()"
      fxFlex="20%"
      mat-raised-button
      fxFlex.xs="100%"
      [disabled]="readOnly"
      color="warn"
    >
      Clear
    </button>
  </div>
</form>
