import { Component, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ThemeService } from './services/theme.service';
import { LoaderService } from './services/loader.service';
import { DOCUMENT } from '@angular/common';
import { WebSocketService } from './services/web-socket.service';
import { SessionService } from './services/session.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  selectedTheme: string;
  showSpinner: boolean;
  isMobile: boolean = false;
  @ViewChild('drawer') drawer: MatDrawer;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public sessionService: SessionService,
    private theme: ThemeService,
    private loader: LoaderService,
    private webSocket: WebSocketService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    
    //Global events
    this.theme.events.subscribe(theme => {
      this.document.body.classList.remove(this.selectedTheme);
      this.selectedTheme = theme;
      this.document.body.classList.add(this.selectedTheme);

    });

    this.loader.events.subscribe(val => {
      this.showSpinner = val;
    });

  }

  logout() {
    if(this.drawer != null)
      this.drawer.close();
    
    this.sessionService.logOut();
  }

  toggleMenu() { 
    this.drawer.toggle();
  }
}
