import { NgModule } from "@angular/core";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatSortModule } from "@angular/material/sort";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  imports: [
    MatToolbarModule, MatButtonModule, MatMenuModule, MatIconModule,
    MatGridListModule, MatCardModule, MatDividerModule, MatFormFieldModule,
    MatProgressSpinnerModule, MatInputModule, MatSnackBarModule,
    MatPaginatorModule, MatTableModule, MatSortModule, MatDialogModule, MatCheckboxModule,
    MatExpansionModule, MatChipsModule, MatDatepickerModule, MatNativeDateModule,
    MatListModule, MatTabsModule, MatButtonToggleModule, MatSidenavModule 
  ],
  exports: [
    MatToolbarModule, MatButtonModule, MatMenuModule, MatIconModule,
    MatGridListModule, MatCardModule, MatDividerModule, MatFormFieldModule,
    MatProgressSpinnerModule, MatInputModule, MatSnackBarModule,
    MatPaginatorModule, MatTableModule, MatSortModule, MatDialogModule, MatCheckboxModule,
    MatExpansionModule, MatChipsModule, MatDatepickerModule, MatNativeDateModule,
    MatListModule, MatTabsModule, MatButtonToggleModule, MatSidenavModule 
  ]
})
export class MaterialModule { }