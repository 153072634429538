export class LookupData {
  label: string;
  placeHolder: string;
  valueKey: string;
  value: any;
  descriptionKey: string;
  description: any;
  service: any;
  serviceRoute: string;

  constructor({ label = "", placeHolder = "", valueKey = "", value = null, descriptionKey = "", description = "", service = null, serviceRoute = "" } = {}) {
    this.label = label;
    this.placeHolder = placeHolder;
    this.valueKey = valueKey;
    this.value = value;
    this.descriptionKey = descriptionKey;
    this.description = description;
    this.service = service;
    this.serviceRoute = serviceRoute;
  }

}