<div class="page-container">

  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <div fxLayout="column" fxFlex="50%" fxFlexOrder="1" fxFlexOrder.lt-lg="2">

      <p>Listado de Publicidades</p>
      <div class="search-div" fxLayout="row" fxLayoutGap="5px">
        <mat-form-field fxFlex="100%">
          <button mat-button matPrefix mat-icon-button aria-label="Borrar" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
          <input matInput [(ngModel)]="searchValue" placeholder="Buscar registro..." autocomplete="off" type="text"
            (keyup)="applyFilter()">
        </mat-form-field>
        <button class="top-right-button" color="primary" mat-raised-button fxFlex="80px" (click)="newRecord()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div class="mat-elevation-z8">
        <mat-table [dataSource]="publicityRecords" matSort>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>
              Acciones
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button color="accent" (click)="viewPublicity(element)">
                <mat-icon>launch</mat-icon>
              </button>
              <button disabled mat-icon-button color="warn" (click)="delete(element._id)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Titulo</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.title}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="img">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Imagen</mat-header-cell>
            <mat-cell *matCellDef="let element" class="text-center">
              <mat-icon *ngIf="element.imgName != null" color="primary">photo_camera</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Todas Las Rutas</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.active" color="primary">check_box</mat-icon>
              <mat-icon *ngIf="!element.active">check_box_outline_blank</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="allRoutes">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Todas Las Rutas</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.allRoutes" color="primary">check_box</mat-icon>
              <mat-icon *ngIf="!element.allRoutes">check_box_outline_blank</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef colspan="6">
              <div class="full-width text-center">
                No hay datos...
              </div>
            </mat-footer-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          <mat-footer-row *matFooterRowDef="['noData']"
            [ngClass]="{'hidden': publicityRecords != null && publicityRecords.data.length > 0}">
          </mat-footer-row>

        </mat-table>
        <mat-paginator [pageSizeOptions]="[15, 30, 50, 100]" [pageSize]="15" showFirstLastButton></mat-paginator>
      </div>

    </div>

    <div fxLayout="column" fxFlex="50%" style="padding-top:20px" fxFlexOrder="2" fxFlexOrder.lt-lg="1">
      <app-publicity-form (dataSubmitted)="getpublicityRecords()"></app-publicity-form>
    </div>
  </div>
</div>