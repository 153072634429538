import { Component, OnInit, HostBinding, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;

  @Output() fileChanged = new EventEmitter<any>();

  @Input() appearance: string = "fill";
  @Input() label: string = "Imagen";
  @Input() placeholder: string = "Seleccionar imagen";
  @Input() disabled: boolean = false;
  @Input() icon: string = "image";
  imgDescription: string = "";
  private file: any = null;

  constructor() { }

  ngOnInit() {
  }

  browseFile() {
    this.fileInput.nativeElement.click();
  }

  handleFileInput(files: FileList) {
    if (!files.length) {
      this.clearData();
      this.fileChanged.emit(null);
      return;
    }
    this.file = files.item(0);
    this.imgDescription = this.file.name;

    this.fileChanged.emit({ isValid: true, file: this.file });
    // formData.append('img', fileToUpload, '123456');
  }

  clearData() {
    this.file = null;
    this.imgDescription = "";
  }
}
