<div class="dialog-container">
  <button class="close-button" mat-icon-button (click)="closeDialog()" style="z-index: 2">
    <mat-icon>clear</mat-icon>
  </button>


  <div class="no-signal-container">
    <br>
    <div fxLayout="row" fxLayout.xs="column" fxFlexAlign.xs="center">
      <div fxFlex="350px" fxFlex.xs="100%">
        <mat-button-toggle-group name="filter" aria-label="Filtro" [(ngModel)]="filterValue" (change)="filterChanged()">
          <mat-button-toggle [value]="'all'">Todos</mat-button-toggle>
          <mat-button-toggle [value]="'active'">Activos</mat-button-toggle>
          <mat-button-toggle [value]="'rest'">Descansos</mat-button-toggle>
          <mat-button-toggle [value]="'inactive'">Inactivos</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <mat-form-field fxLayout="row">
        <button mat-button matPrefix mat-icon-button aria-label="Borrar" (click)="clearSearch()">
          <mat-icon>close</mat-icon>
        </button>
        <input matInput [(ngModel)]="searchValue" placeholder="Buscar..." autocomplete="off" type="text" fxFlex="calc(100%-20px)"
          (keyup)="applyFilter()">
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <div class="inner" fxFlex="100%">
        <table mat-table #iSort="matSort" matSort [dataSource]="busesDS" class="mat-elevation-z8">
          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> #
            </th>
            <td mat-cell *matCellDef="let ab"> {{ab.number}}</td>
          </ng-container>

          <ng-container matColumnDef="route">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ruta
            </th>
            <td mat-cell *matCellDef="let ab"> {{ab.route ? ab.route.name : 'Sin ruta'}}</td>
          </ng-container>

          <ng-container matColumnDef="deviceId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> GPS
            </th>
            <td mat-cell *matCellDef="let ab"> {{ab.deviceId}}</td>
          </ng-container>

          <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SIM
            </th>
            <td mat-cell *matCellDef="let ab"> {{ab.phoneNumber}}</td>
          </ng-container>

          <ng-container matColumnDef="lastPing">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Ultima Señal </th>
            <td mat-cell *matCellDef="let ab"> {{ab.lastPing}} </td>
          </ng-container>

          <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let ab">
              <button mat-icon-button>
                <mat-icon color="primary">visibility</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>

</div>