import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  openConfirmDialog(message: string): any {
    return this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      autoFocus: true,
      data: {
        message: message
      },
      scrollStrategy: new NoopScrollStrategy()
    })
  }

  openComponentDialog(component: any, data: any): any {

    return new Promise((resolve, reject) => {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.width = "60%";
      dialogConfig.panelClass = "form-dialog";
      dialogConfig.scrollStrategy = new NoopScrollStrategy();
      dialogConfig.data = data;

      this.dialog.open(component, dialogConfig).beforeClosed().subscribe((data) => {
        resolve(data);
      }, err => reject(err));
    });

  }
}
