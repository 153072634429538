<div class="div-margins">
  <div fxLayout="row" class="padding">
    <label>{{label}}</label>
  </div>
  <div fxLayout="row" class="file-input-container">
    <app-file-input [label]="'Cargar Archivo'" [placeholder]="'Sin Archivo Seleccionado...'" [icon]="'description'"
      class="full-width" (fileChanged)="fileChanged($event)"></app-file-input>
  </div>
  <div *ngIf="description.length" fxLayout="row" class="description-container">
    <p class="smaller-text">{{description}}</p>
  </div>
  <mat-divider></mat-divider>
  <div fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="end" class="padding">
    <button color="primary" mat-raised-button (click)="submit()">{{confirmButtonText}}</button>
    <button mat-raised-button (click)="cancel()">{{cancelButtonText}}</button>
  </div>
</div>