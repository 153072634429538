<div class="page-container">

  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <div fxLayout="column" fxFlex="50%" fxFlexOrder="1" fxFlexOrder.lt-lg="2">

      <p>Listado de Reportes De Usuarios</p>
      <div class="search-div" fxLayout="row">
        <mat-form-field fxFlex="100%">
          <button mat-button matPrefix mat-icon-button aria-label="Borrar" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
          <input matInput [(ngModel)]="searchValue" placeholder="Buscar reporte..." autocomplete="off" type="text"
            (keyup)="applyFilter()">
        </mat-form-field>

        <!-- <input type="file" (change)="handleFileInput($event.target.files)" #fileInput id="file"> -->
      </div>

      <div class="mat-elevation-z8">
        <mat-table [dataSource]="reports" matSort>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>
              Acciones
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button color="accent" (click)="viewReport(element)">
                <mat-icon>launch</mat-icon>
              </button>
              <button disabled mat-icon-button color="warn" (click)="delete(element._id)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="bus">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Autobus Reportado</mat-header-cell>
            <mat-cell *matCellDef="let element">{{(element.bus != null ? ('Autobus ' + element.bus.number) : 'No
              Asignado')}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Titulo</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.title}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.date | formatDate : true}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ticketNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header># Boleto</mat-header-cell>
            <mat-cell *matCellDef="let element" class="text-center">{{element.ticketNumber || ''}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="withResponse">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Respondido</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.response" color="primary">check_box</mat-icon>
              <mat-icon *ngIf="!element.response">check_box_outline_blank</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef colspan="6">
              <div class="full-width text-center">
                No hay datos...
              </div>
            </mat-footer-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          <mat-footer-row *matFooterRowDef="['noData']"
            [ngClass]="{'hidden': reports != null && reports.data.length > 0}">
          </mat-footer-row>

        </mat-table>
        <mat-paginator [pageSizeOptions]="[15, 30, 50, 100]" [pageSize]="15" showFirstLastButton></mat-paginator>
      </div>

    </div>

    <div fxLayout="column" fxFlex="50%" style="padding-top:20px" fxFlexOrder="2" fxFlexOrder.lt-lg="1">
      <div *ngIf="selectedReport == null" fxLayoutAlign="space-around center" style="height:200px">
        <p class="caption">Seleccione un reporte</p>
      </div>
      <app-report-form [ngStyle]="{'display': (selectedReport != null) ? 'block' : 'none'}"
        [selectedReport]="selectedReport" (dataSubmitted)="getReports()"></app-report-form>
    </div>
  </div>
</div>