<mat-toolbar style="border-radius: 5px">
  <h2 *ngIf="readOnly"><mat-icon>user</mat-icon> Edit User</h2>
  <h2 *ngIf="!readOnly">
    <mat-icon>user</mat-icon>
    {{ form.controls["_id"].value ? "Modify User" : "New User" }}
  </h2>
  <span class="fill-remaining-space"></span>
  <button class="btn-dialog-close" mat-button (click)="close()" tabIndex="-1">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>

<br />

<form
  #userForm
  [formGroup]="form"
  (submit)="submit()"
  class="padding-top padding-right padding-left"
>
  <input type="hidden" formControlName="_id" />

  <div fxLayout="row" fxLayoutGap="1%">
    <div fxLayout="column" fxFlex="50%">
      <!-- <div fxLayout="row" fxLayoutGap="1%" fxLayout.xs="column"> -->
      <mat-form-field>
        <mat-label>Username*</mat-label>
        <!-- <mat-icon matPrefix>directions_user</mat-icon> -->
        <input
          #firstInput
          formControlName="username"
          matInput
          placeholder="Nombre de usuario"
        />
        <mat-error *ngIf="form.controls['username'].errors?.required"
          >Required field</mat-error
        >
        <mat-error *ngIf="form.controls['username'].errors?.minlength"
          >Minimo 4 caracteres</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Full Name</mat-label>
        <!-- <mat-icon matPrefix>hash</mat-icon> -->
        <input formControlName="name" matInput placeholder="Nombre Completo" />
      </mat-form-field>
      <!-- </div> -->

      <!-- <div fxLayout="row" fxLayoutGap="1%" fxLayout.xs="column"> -->
      <mat-form-field>
        <mat-label>Email*</mat-label>
        <!-- <mat-icon matPrefix>directions_user</mat-icon> -->
        <input
          #firstInput
          formControlName="email"
          matInput
          placeholder="Correo electronico"
        />
        <mat-error *ngIf="form.controls['email'].errors?.required"
          >Required field</mat-error
        >
        <mat-error *ngIf="form.controls['email'].errors?.email"
          >Invalid Email</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Password*</mat-label>
        <!-- <mat-icon matPrefix>hash</mat-icon> -->
        <input
          formControlName="password"
          type="password"
          matInput
          placeholder="Contraseña"
        />
        <mat-error *ngIf="form.controls['password'].errors?.required"
          >Required field</mat-error
        >
        <mat-error *ngIf="form.controls['password'].errors?.minlength"
          >Minimum of 6 characters</mat-error
        >
      </mat-form-field>
      <!-- </div> -->

      <div fxLayout="row" fxLayoutGap="1%" fxLayout.xs="column">
        <div fxFlex="50%" style="padding-top: 20px">
          <mat-checkbox formControlName="active" matInput color="primary"
            >Active</mat-checkbox
          >
        </div>

        <div fxFlex="50%" style="padding-top: 20px">
          <mat-checkbox formControlName="isAdmin" matInput color="primary"
            >Administrator</mat-checkbox
          >
        </div>
      </div>
    </div>

    <div fxLayout="column" fxFlex="50%">
      <app-user-security
        [userPermissions]="userPermissions"
      ></app-user-security>
    </div>
  </div>

  <div class="padding-top">
    <mat-divider></mat-divider>
  </div>
  <br />

  <div
    class="padding-top"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutGap="1%"
    fxLayoutAlign="end"
  >
    <button
      type="submit"
      *ngIf="!readOnly"
      mat-raised-button
      color="primary"
      fxFlex="20%"
      fxFlex.xs="100%"
      [disabled]="form.invalid"
    >
      Save
    </button>

    <button
      type="button"
      *ngIf="readOnly && form.controls['_id'].value != null"
      (click)="enableForm()"
      fxFlex="20%"
      mat-raised-button
      fxFlex.xs="100%"
      color="accent"
    >
      Save
    </button>

    <button
      type="button"
      (click)="clearForm()"
      fxFlex="20%"
      mat-raised-button
      fxFlex.xs="100%"
      color="warn"
    >
      Clear
    </button>
  </div>
</form>
