import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LoaderService } from "src/app/services/loader.service";
import { NotificationService } from "src/app/services/notification.service";
import { ReportService } from "src/app/services/report.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss']
})
export class ReportFormComponent implements OnInit {

  _selectedReport: any = {
    title: 'No hay un reporte seleccionado',
    bus: null,
    route: null,
    description: null,
    imgName: null,
    contactPhoneNumber: null,
    date: new Date()
  };
  imagesURL: string = environment.serverUrl;
  @Input() set selectedReport(value: any) {
    if (value == null)
      this.resetFormValue();
    else
      this._selectedReport = value;
  };
  @Output() closedForm = new EventEmitter<any>();
  @Output() dataSubmitted = new EventEmitter<any>();

  form: FormGroup = new FormGroup({
    response: new FormControl('', [Validators.required, Validators.minLength(5)]),
  });

  constructor(
    private loader: LoaderService,
    private notification: NotificationService,
    private reportService: ReportService
  ) { }

  ngOnInit() {

  }

  resetFormValue() {
    this._selectedReport = {
      title: 'No hay un reporte seleccionado',
      bus: null,
      route: null,
      description: null,
      imgName: null,
      contactPhoneNumber: null,
      date: new Date()
    };
  }

  respondReport() {
    let formValue = this.form.value;
    this.loader.showSpinner();
    this.reportService.respond(this._selectedReport._id, formValue.response).then(updatedReport => {
      this._selectedReport = updatedReport;
      this.dataSubmitted.emit(updatedReport);
    }).catch(err => {
      this.notification.showWarn('Ocurrio un error al responder el reporte');
    }).finally(() => {
      this.loader.hideSpinner();
    });
  }

}