<div class="base-background" fxLayout fxFill fxLayoutAlign="space-around center">
  <mat-card class="login-card">
    <mat-card-header color="primary" class="login-header text-center">
      <mat-card-title>Login</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>
    <br>
    <form [formGroup]="loginForm" (submit)="login()" fxLayout="column" class="text-center">

      <mat-card-content>

        <mat-form-field style="width:80%;">
          <input formControlName="username" matInput placeholder="Usuario">
          <mat-error *ngIf="loginForm.controls['username'].errors?.required">Campo necesario</mat-error>
          <mat-error *ngIf="loginForm.controls['username'].errors?.minlength">Minimo 4 caracteres</mat-error>
        </mat-form-field>

        <mat-form-field style="width:80%;">
          <input formControlName="password" matInput placeholder="Contraseña" type="password">
          <mat-error *ngIf="loginForm.controls['password'].errors?.required">Campo necesario</mat-error>
          <mat-error *ngIf="loginForm.controls['password'].errors?.minlength">Minimo 6 caracteres</mat-error>
        </mat-form-field>

      </mat-card-content>

      <mat-card-actions class="text-center">
        <button class="bigger-button" type="submit" mat-raised-button color="primary"
          [disabled]="loginForm.invalid">Login</button>
      </mat-card-actions>

      <br>

      <p *ngIf="loginError">{{errorMessage}}</p>

    </form>

  </mat-card>
</div>