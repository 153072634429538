import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LookupData } from './LookupData';

@Component({
  selector: 'app-lookup-list',
  templateUrl: './lookup-list.component.html',
  styleUrls: ['./lookup-list.component.scss']
})
export class LookupListComponent implements OnInit {

  records: MatTableDataSource<any>;
  searchValue: string;
  displayedColumns: string[] = ['actions', 'description'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public objectData: LookupData,
    private dialogRef: MatDialogRef<LookupListComponent>,
  ) { }

  ngOnInit(): void {
    let service = this.injector.get<any>(this.objectData.service);

    service.get(this.objectData.serviceRoute).then(data => {
      data = data.map(d => {
        return {
          value: d[this.objectData.valueKey],
          description: d[this.objectData.descriptionKey]
        };
      }) || [];

      this.records = new MatTableDataSource(data);
      this.records.sort = this.sort;
      this.records.paginator = this.paginator;
    }).catch(err => {
      console.log(err);
    });
  }

  clearSearch() {
    this.searchValue = "";
    this.applyFilter();
  }

  applyFilter() {
    this.records.filter = this.searchValue.trim().toLowerCase();
  }

  close(closingData: any = null) {
    this.dialogRef.close(closingData);
  }

  selectRecord(element: any) {
    this.close(element);
  }

  unSelectRecord() {
    this.close({ clearValue: true });
  }

}
