import { Injectable } from '@angular/core';
import { Observable } from 'Rxjs';
import * as IO from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  socket: IO.Socket;
  constructor() { }

  connect() {
    return new Promise((resolve, reject) => {
      this.socket = IO(environment.serverUrl);
      this.socket.on('connect', () => {
        resolve(true);
      });
      this.socket.on('connect_error', (err) => {
        reject(err);
      })
    });
  }

  listen(eventName: string) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }

  stopListening(eventName: string) {
    this.socket.off(eventName);
  }

  emit(eventName: string, data: any, callback = null) {
    this.socket.emit(eventName, data, (data) => {
      if (callback != null && typeof callback == "function")
        callback(data);
    });
  }


}
