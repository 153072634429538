import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService extends BaseService {
  // routeList : Array<
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    name: new FormControl('', [Validators.required, Validators.minLength(6)]),
    active: new FormControl(false)
  });

  constructor(private http_: HttpClient) {
    super(http_);
    this.url = 'route';
  }

  //Resets the form
  initializeForm() {
    this.form.setValue({
      _id: null,
      name: '',
      active: false
    });
  }

  populateForm(route: any) {
    this.form.patchValue(route);
  }

  //Posts form value
  post(form?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      super.post(form || this.form.value)
        .then(data => {
          resolve(data);
        }).catch(err => {
          reject(err);
        });
    });
  }

  //Updates form value
  update(form?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      super.put(form || this.form.value)
        .then(data => {
          resolve(data);
        }).catch(err => {
          reject(err);
        });
    })
  }

  isValidRoute(route: any): boolean {
    if (!route)
      return false;

    if (!route.name)
      return false;


    return true;
  }

}
