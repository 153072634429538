import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BusService } from 'src/app/services/bus.service';
import { DialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { BusFormComponent } from './bus-form/bus-form.component';

@Component({
  selector: 'app-bus-page',
  templateUrl: './bus-page.component.html',
  styleUrls: ['./bus-page.component.scss']
})
export class BusPageComponent implements OnInit {

  showForm: boolean = false;
  searchValue: string;
  selectedBus: any = null;

  @ViewChild(BusFormComponent) formElement: BusFormComponent;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  buses: MatTableDataSource<any>;
  displayedColumns: string[] = ['actions', 'number', 'name', 'active', 'route', 'device'];

  constructor(
    public busService: BusService,
    private loader: LoaderService,
    private notification: NotificationService,
    private dialogService: DialogService
  ) {
  }

  ngOnInit() {
    this.getBuses();
  }

  getBuses() {
    this.loader.showSpinner();

    this.busService.get().then(data => {
      this.buses = new MatTableDataSource(data);
      this.buses.filterPredicate = this.filterFunc;
      this.buses.sort = this.sort;
      this.buses.paginator = this.paginator;
    }).catch(ex => {
      this.notification.showWarn('Error al cargar listado de autobuses');
      console.log(ex);
    }).finally(() => {
      this.loader.hideSpinner();
    });
  }

  newRecord() {
    this.showForm = true;
    setTimeout(() => {
      this.formElement.clearForm();
      this.formElement.enableForm();
    }, 200);

  }

  edit(bus) {
    if (this.formElement) {
      this.formElement.clearForm();
      this.selectedBus = bus;
      this.formElement.populateForm(this.selectedBus);
    } else {
      this.selectedBus = bus;
      this.showForm = true;
    }
  }

  delete(id: string) {
    this.dialogService.openConfirmDialog('Seguro que desea eliminar este autobus?')
      .afterClosed().subscribe(res => {
        if (!res)
          return;

        this.loader.showSpinner();
        this.busService.delete(id).then((data) => {
          this.notification.showSuccess('Autobus eliminado correctamente');
          this.getBuses();
        }).catch(err => {
          this.notification.showWarn(err.message || 'Ocurrio un error al tratar de eliminar el autobus');
        }).finally(() => this.loader.hideSpinner());
      });
  }

  closeForm() {
    this.showForm = false;
  }

  applyFilter() {
    this.buses.filter = this.searchValue.trim().toLowerCase();
  }

  clearSearch() {
    this.searchValue = "";
    this.applyFilter();
  }

  filterFunc(data: any, filter: string): boolean {
    if (data.number.indexOf(filter) >= 0)
      return true;
    if (data.name.indexOf(filter) >= 0)
      return true;
    if (data.route && data.route.name.toLowerCase().indexOf(filter) >= 0)
      return true;
    // if (data.device && data.device.deviceId.toLowerCase().indexOf(filter) >= 0)
    //   return true;

    return false;
  }
}
