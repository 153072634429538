import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent implements OnInit {

  showForm: boolean = false;
  searchValue: string;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  reports: MatTableDataSource<any>;
  displayedColumns: string[] = ['actions', 'bus', 'title', 'date', 'ticketNumber', 'withResponse'];

  selectedReport: any = null;


  constructor(
    private loader: LoaderService,
    private notification: NotificationService,
    private dialogService: DialogService,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.getReports();
  }

  getReports() {
    this.loader.showSpinner();
    this.reportService.get().then(data => {
      this.reports = new MatTableDataSource(data);
      this.reports.sort = this.sort;
      this.reports.paginator = this.paginator;
    }).catch(err => {
      this.notification.showWarn('Error al cargar listado de reportes');
    }).finally(() => {
      this.loader.hideSpinner();
    })
  }

  viewReport(reportData) {
    this.selectedReport = reportData;
  }

  applyFilter() {
    this.reports.filter = this.searchValue.trim().toLowerCase();
  }

  clearSearch() {
    this.searchValue = "";
    this.applyFilter();
  }



}
