<div>
  <!-- Totals -->
  <div
    *ngIf="!isMobile"
    class="totals-container"
    fxLayout="row wrap"
    fxLayoutGap="10px"
    fxLayoutAlign="center"
  >
    <mat-card fxFlex="18%" class="hover-card" (click)="showBusesDetail('all')">
      <mat-card class="icon-card cyan">
        <mat-icon>share_location</mat-icon>
      </mat-card>
      <div class="text-right">
        <span class="soft-text">Total</span>
        &nbsp;
        <span class="big-text cyan">{{ dashboardData.total }}</span>
        <br />
        <span class="soft-text smaller-text">Check Details</span>
      </div>
    </mat-card>

    <mat-card
      fxFlex="18%"
      class="hover-card"
      (click)="showBusesDetail('active')"
    >
      <mat-card class="icon-card green">
        <mat-icon>share_location</mat-icon>
      </mat-card>
      <div class="text-right">
        <span class="soft-text">Active</span>
        &nbsp;
        <span class="big-text green">{{ dashboardData.activeBuses }}</span>
        <br />
        <span class="soft-text smaller-text">Check Details</span>
      </div>
    </mat-card>
    <!-- 
    <mat-card
      fxFlex="18%"
      fxFlex.lt-lg="33%"
      class="hover-card"
      (click)="showBusesDetail('rest')"
    >
      <mat-card class="icon-card yellow">
        <mat-icon>share_location</mat-icon>
      </mat-card>
      <div class="text-right">
        <span class="soft-text">En Descanso</span>
        &nbsp;
        <span class="big-text yellow">{{ dashboardData.rest }}</span>
        <br />
        <span class="soft-text smaller-text">Check Details</span>
      </div>
    </mat-card> -->

    <mat-card
      fxFlex="18%"
      class="hover-card"
      (click)="showBusesDetail('inactive')"
    >
      <mat-card class="icon-card red">
        <mat-icon>share_location</mat-icon>
      </mat-card>
      <div class="text-right">
        <span class="soft-text">Inactive</span>
        &nbsp;
        <span class="big-text red">{{ dashboardData.inactiveBuses }}</span>
        <br />
        <span class="soft-text smaller-text">Check Details</span>
      </div>
    </mat-card>
    <div fxFlex="16%">
      <app-lookup
        [lookupData]="routeLookupData"
        [appearance]="'fill'"
        (onChange)="routeChanged($event)"
      ></app-lookup>
    </div>
    <div fxFlex="16%">
      <app-lookup
        [lookupData]="busLookupData"
        [appearance]="'fill'"
        (onChange)="busChanged($event)"
      ></app-lookup>
    </div>
    <div class="view-all-container" fxLayoutAlign="end">
      <button
        mat-raised-button
        color="accent"
        (click)="viewAllBuses()"
        [disabled]="activeFilter == 'all'"
      >
        Track all
      </button>
    </div>
  </div>

  <div *ngIf="isMobile" class="mobile-totals-container">
    <div fxLayout="row" fxLayoutGap="5px">
      <mat-card
        fxFlex="50%"
        class="hover-card"
        fxLayoutAlign="space-around"
        (click)="showBusesDetail('all')"
      >
        <span class="soft-text">Total</span>
        <span class="big-text cyan">{{ dashboardData.total }}</span>
      </mat-card>

      <mat-card
        fxFlex="50%"
        class="hover-card"
        fxLayoutAlign="space-around"
        (click)="showBusesDetail('active')"
      >
        <span class="soft-text">Activos</span>
        <span class="big-text green">{{ dashboardData.activeBuses }}</span>
      </mat-card>
    </div>

    <div fxLayout="row" fxLayoutGap="5px">
      <mat-card
        fxFlex="50%"
        class="hover-card"
        fxLayoutAlign="space-around"
        (click)="showBusesDetail('rest')"
      >
        <span class="soft-text">Descansos</span>
        <span class="big-text yellow">{{ dashboardData.rest }}</span>
      </mat-card>

      <mat-card
        fxFlex="50%"
        class="hover-card"
        fxLayoutAlign="space-around"
        (click)="showBusesDetail('inactive')"
      >
        <span class="soft-text">Desconectados</span>
        <span class="big-text red">{{ dashboardData.inactiveBuses }}</span>
      </mat-card>
    </div>
  </div>

  <div
    *ngIf="!isMobile"
    fxLayout="row wrap"
    fxLayoutGap="10px"
    fxLayoutAlign="center"
  >
    <!-- <div fxFlex="18%" fxFlex.lt-lg="40%">
      <app-lookup
        [lookupData]="routeLookupData"
        [appearance]="'fill'"
        (onChange)="routeChanged($event)"
      ></app-lookup>
    </div>
    <div fxFlex="18%" fxFlex.lt-lg="40%">
      <app-lookup
        [lookupData]="busLookupData"
        [appearance]="'fill'"
        (onChange)="busChanged($event)"
      ></app-lookup>
    </div>
    <div
      fxFlex="10%"
      fxFlex.lt-lg="18%"
      class="view-all-container"
      fxLayoutAlign="end"
    >
      <button
        mat-raised-button
        color="accent"
        (click)="viewAllBuses()"
        [disabled]="activeFilter == 'all'"
      >
        <mat-icon>search</mat-icon>
        Ver Todos
      </button>
    </div> -->
  </div>
</div>

<span *ngIf="isMobile" class="mobile-filters">
  <span class="mobile-filter">
    <app-lookup
      [lookupData]="routeLookupData"
      [appearance]="'fill'"
      (onChange)="routeChanged($event)"
    ></app-lookup>
  </span>
  <span class="mobile-filter">
    <app-lookup
      [lookupData]="busLookupData"
      [appearance]="'fill'"
      (onChange)="busChanged($event)"
    ></app-lookup>
  </span>
</span>

<div id="map-wrapper" class="mat-elevation-z8"></div>
