import { Component, OnInit, Inject, ViewChild, ElementRef, Optional, Output, EventEmitter, Input } from '@angular/core';
import { BusService } from 'src/app/services/bus.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { NotificationService } from 'src/app/services/notification.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LookupData } from 'src/app/components/lookup/LookupData';
import { RouteService } from 'src/app/services/route.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-bus-form',
  templateUrl: './bus-form.component.html'
})
export class BusFormComponent implements OnInit {
  @Input() selectedBus: any;
  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    name: new FormControl('', [Validators.required, Validators.minLength(6)]),
    number: new FormControl('', [Validators.required, Validators.minLength(1)]),
    route: new FormControl(''),
    device: new FormControl(''),
    active: new FormControl(false)
  });

  @ViewChild('firstInput') firstInput: ElementRef;

  @Output() closedForm = new EventEmitter<any>();
  @Output() dataSubmitted = new EventEmitter<any>();

  routeLookupData: LookupData;
  deviceLookupData: LookupData;
  routeHistory = [];
  deviceHistory: Array<any> = [];

  readOnly: boolean = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    public busService: BusService,
    private notification: NotificationService,
    private loader: LoaderService,
    @Optional() private dialogRef: MatDialogRef<BusFormComponent>
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.populateForm(this.selectedBus);
  }

  getData() {
    return new Promise((resolve, reject) => {
      return this.busService.get().then(data => resolve(data)).catch(err => reject(err));
    });
  }

  //Resets the form
  initializeForm() {
    this.form.setValue({
      _id: null,
      name: '',
      number: '',
      route: null,
      device: null,
      active: false
    });
    this.initializeLookups();
  }

  initializeLookups() {
    this.routeLookupData = new LookupData({
      valueKey: '_id',
      descriptionKey: 'name',
      label: 'Route',
      placeHolder: 'Assign Route',
      service: RouteService
    });
    this.deviceLookupData = new LookupData({
      valueKey: '_id',
      descriptionKey: 'deviceId',
      label: 'GPS Device',
      placeHolder: 'Assign GPS Device',
      service: DeviceService,
      serviceRoute: '/available'
    });
  }

  populateForm(bus: any) {
    this.form.reset();

    let formValue = {
      _id: bus._id,
      name: bus.name,
      number: bus.number,
      route: bus.route != null ? bus.route._id : null,
      device: bus.device != null ? bus.device._id : null,
      active: bus.active
    };

    this.loadRouteHistory(bus._id);
    this.loadDeviceHistory(bus._id);

    this.routeLookupData.value = formValue.route;
    this.routeLookupData.description = this.selectedBus.route != null ? this.selectedBus.route.name : "";

    this.deviceLookupData.value = formValue.device;
    this.deviceLookupData.description = this.selectedBus.device != null ? this.selectedBus.device.deviceId : "";

    this.form.patchValue(formValue);
  }

  loadRouteHistory(busId: string) {
    this.busService.loadRouteHistory(busId).then(data => {
      this.routeHistory = data;
    });
  }

  loadDeviceHistory(busId: string) {
    this.busService.loadDeviceHistory(busId).then(data => {
      this.deviceHistory = data;
    })
  }

  clearForm() {
    this.form.reset();
    this.initializeForm();
    this.deviceHistory = [];
    this.routeHistory = [];
  }

  submit() {
    if (!this.form.valid)
      return this.notification.showWarn("Invalid Form", "Ok", 3000);

    this.loader.showSpinner();

    if (this.form.get('_id').value == "" || this.form.get('_id').value == null) {
      this.busService.post(this.form.value).then((createdBus: any) => {
        this.dataSubmitted.emit(true);
        this.loadDeviceHistory(createdBus._id);
        this.loadRouteHistory(createdBus._id);
      }).catch(err => {
        this.notification.showWarn(err.message || "Ocurrio un error al registrar el nuevo autobus");
      }).finally(() => this.loader.hideSpinner());
    }
    else {

      this.busService.update(this.form.value).then((updatedBus) => {
        this.dataSubmitted.emit(true);
        this.loadDeviceHistory(updatedBus._id);
        this.loadRouteHistory(updatedBus._id);
      }).catch(err => {
        this.notification.showWarn(err.message || "Ocurrio un error al registrar el nuevo autobus");
      }).finally(() => this.loader.hideSpinner());
    }

  }

  routeValueChanged(newRoute: any) {
    this.form.patchValue({ route: newRoute });
  }

  deviceValueChanged(newDevice: any) {
    this.form.patchValue({ device: newDevice });
  }

  enableForm() {
    this.readOnly = false;
    this.form.enable();
    this.firstInput.nativeElement.focus();
  }

  disableForm() {
    this.readOnly = true;
  }

  close() {
    let closingData = Object.assign(this.form.value, this.form.value);

    if (this.dialogRef != null)
      this.dialogRef.close(closingData);

    this.closedForm.emit(true);
    this.form.reset();
    this.initializeForm();
  }

}
