import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { LookupListComponent } from './lookup-list.component';
import { LookupData } from './LookupData';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss']
})
export class LookupComponent implements OnInit {

  @Input() lookupData: LookupData = new LookupData();
  @Input() appearance: string = "";
  @Input() showClearBtn: boolean = false;
  @Output() onChange = new EventEmitter<any>();

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void { }

  openSearchList() {

    this.dialogService.openComponentDialog(LookupListComponent, this.lookupData).then(dialogData => {
      if (dialogData.clearValue) {
        this.lookupData.value = null;
        this.lookupData.description = "";
        this.onChange.emit(null);
      }
      else if (dialogData.value) {
        this.lookupData.value = dialogData.value;
        this.lookupData.description = dialogData.description;
        this.onChange.emit(dialogData.value);
      }

    }).catch(err => {
      console.log('trono');
    });
  }

  clear() {
    this.lookupData.value = null;
    this.lookupData.description = "";
    this.onChange.emit(null);
  }

}
