// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // serverUrl: 'http://localhost:3000/',
  serverUrl: 'http://35.222.47.117:3000/',
  // serverUrl: 'http://localhost:3000/',
  mapBoxKey: 'pk.eyJ1IjoidmFsZW50aW5nYXJjaWEiLCJhIjoiY2ttZHVrc2gzMmNreTJxczl4OWM1Zm5sdCJ9.g81qxlH2FPPxzogeXwhE7Q',
  mapCenter: { lat: 25.778242, lng: -108.994954 }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// 6681977680