import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AppUserService extends BaseService {
  constructor(private http_: HttpClient) {
    super(http_);
    this.url = 'appUser';
  }

  find(body) {
    return new Promise((resolve, reject) => {
      resolve([]);
    });
  }
}
