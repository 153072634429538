import { Component, ElementRef, EventEmitter, Inject, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceService } from 'src/app/services/device.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.scss']
})
export class DeviceFormComponent implements OnInit {

  @ViewChild('firstInput') firstInput: ElementRef;
  @Output() closedForm = new EventEmitter<any>();
  @Output() dataSubmitted = new EventEmitter<any>();

  readOnly: boolean = false;

  form: FormGroup = new FormGroup({
    _id: new FormControl(''),
    deviceId: new FormControl('', [Validators.required, Validators.minLength(10)]),
    phoneNumber: new FormControl('', [Validators.minLength(10)]),
    description: new FormControl(''),
    active: new FormControl(true)
  });

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    public deviceService: DeviceService,
    private notification: NotificationService,
    private loader: LoaderService,
    @Optional() private dialogRef: MatDialogRef<DeviceFormComponent>
  ) { }

  ngOnInit(): void {
  }

  initializeForm() {
    this.form.setValue({
      _id: null,
      deviceId: '',
      description: '',
      phoneNumber: '',
      active: true
    });
  }

  populateForm(device: any) {
    this.initializeForm();
    this.form.patchValue(device);
  }

  clearForm() {
    this.form.reset();
  }

  submit() {
    if (!this.form.valid)
      return this.notification.showWarn("Formulario invalido", "Ok", 3000);

    this.loader.showSpinner();

    if (this.form.get('_id').value == "" || this.form.get('_id').value == null) {
      this.deviceService.post(this.form.value).then((result: any) => {
        this.dataSubmitted.emit(true);
        this.clearForm();
      }).catch(err => {
        this.notification.showWarn(err.message || "Ocurrio un error al registrar el nuevo dispositivo GPS");
      }).finally(() => this.loader.hideSpinner());
    }
    else {

      this.deviceService.put(this.form.value).then(() => {
        this.dataSubmitted.emit(true);
      }).catch(err => {
        this.notification.showWarn(err.message || "Ocurrio un error al guardar el dispositivo GPS");
      }).finally(() => this.loader.hideSpinner());
    }

  }

  enableForm() {
    this.readOnly = false;
    this.form.enable();
    this.firstInput.nativeElement.focus();
  }

  disableForm() {
    this.readOnly = true;
  }

  close() {
    let closingData = Object.assign(this.form.value, this.form.value);

    if (this.dialogRef != null)
      this.dialogRef.close(closingData);

    this.closedForm.emit(true);
    this.form.reset();
    this.initializeForm();
  }

}
