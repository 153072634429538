import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RouteService } from 'src/app/services/route.service';
import { RouteFormComponent } from './route-form/route-form.component';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-routes-page',
  templateUrl: './routes-page.component.html',
  styleUrls: ['./routes-page.component.scss']
})
export class RoutesPageComponent implements OnInit {

  showForm: boolean = false;
  searchValue: string;

  formValueChanged = new EventEmitter<boolean>();
  formElement: RouteFormComponent;
  @ViewChild(RouteFormComponent) set content(content: RouteFormComponent) {
    this.formElement = content;
    this.formValueChanged.emit(true);
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  routes: MatTableDataSource<any>;
  displayedColumns: string[] = ['actions', 'name', 'places', 'active', 'routeLength'];

  constructor(
    private routeService: RouteService,
    private loader: LoaderService,
    private notification: NotificationService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getRoutes();
    }, 100);
  }

  getRoutes() {
    this.loader.showSpinner();

    this.routeService.get().then(data => {
      this.routes = new MatTableDataSource(data);
      this.routes.sort = this.sort;
      this.routes.paginator = this.paginator;
    }).catch(ex => {
      this.notification.showWarn('Error al cargar listado de rutas');
      console.log(ex);
    }).finally(() => {
      this.loader.hideSpinner();
    });
  }

  newRecord() {
    this.showForm = true;
    setTimeout(() => {
      this.formElement.clearForm();
      this.formElement.enableForm();
    }, 200);
  }

  edit(route) {
    this.routeService.populateForm(route);

    if (this.formElement)
      this.formElement.setRouteWayPoints(route.wayPoints);
    else
      this.formValueChanged.pipe(first()).subscribe(() => {
        this.formElement.places = route.places;
        this.formElement.mapLoaded.pipe(first()).subscribe(() => {
          this.formElement.setRouteWayPoints(route.wayPoints);
        });
      });

    this.showForm = true;
  }

  delete(id: string) {
    this.dialogService.openConfirmDialog('Seguro que desea eliminar esta ruta?')
      .afterClosed().subscribe(res => {
        if (!res)
          return;

        this.loader.showSpinner();
        this.routeService.delete(id).then((data) => {
          this.notification.showSuccess('Ruta eliminada correctamente');
          this.getRoutes();
        }).catch(err => {
          this.notification.showWarn(err.message || 'Ocurrio un error al tratar de eliminar esta ruta');
        }).finally(() => this.loader.hideSpinner());
      });
  }

  closeForm() {
    this.showForm = false;
  }

  applyFilter() {
    this.routes.filter = this.searchValue.trim().toLowerCase();
  }

  clearSearch() {
    this.searchValue = "";
    this.applyFilter();
  }

  uploadXLSX() {
    this.dialogService.openComponentDialog(FileUploadComponent, {
      label: "Seleccione archivo de rol de rutas",
      description: "El formato del archivo excel debe ser ['#', '# Autobus', 'Nombre Ruta', 'Nombre Chofer']",
      confirmButtonText: "Actualizar Rutas",
      cancelButtonText: "Cancelar",
      submmit: (data) => {
        if (!data.file) {
          this.notification.show("Archivo no cargado");
          return;
        }
        let body = new FormData();
        body.append('rolRutas', data.file, data.file.name);
        this.routeService.custom('updateWithFile', body).then((data) => {
          if (data?.successful?.length)
            this.notification.showSuccess(data.successful.length + " registros se actualizaron correctamente");
          else
            this.notification.showWarn("Ningun registro actualizado");

          this.closeForm();
          this.getRoutes();
        }).catch(err => {
          if (err.message)
            this.notification.showWarn(err.message);
          else
            this.notification.showWarn("Error al cargar archivo");
        })
      }
    });
  }

}
