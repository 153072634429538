import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './modules/material.module';
import { AppRoutingModule } from './modules/app-routing.module';

import { AppComponent } from './app.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { ThemePickerComponent } from './components/theme-picker/theme-picker.component';
import { LoaderService } from './services/loader.service';
import { ThemeService } from './services/theme.service';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { Page404Component } from './pages/page404/page404.component';
import { BusPageComponent } from './pages/bus-page/bus-page.component';
import { RoutesPageComponent } from './pages/routes-page/routes-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { HistoryPageComponent } from './pages/history-page/history-page.component';
import { BaseService } from './services/base.service';
import { BusService } from './services/bus.service';
import { HttpClientModule } from '@angular/common/http';
import { NotificationService } from './services/notification.service';
import { BusFormComponent } from './pages/bus-page/bus-form/bus-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogService } from './services/dialog.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { WebSocketService } from './services/web-socket.service';
import { RouteFormComponent } from './pages/routes-page/route-form/route-form.component';
import { RouteService } from './services/route.service';
import { MapService } from './services/map.service';
import { PipesModule } from './modules/pipes.module';
import { UserFormComponent } from './pages/users-page/user-form/user-form.component';
import { UserService } from './services/user.service';
import { LookupComponent } from './components/lookup/lookup.component';
import { LookupListComponent } from './components/lookup/lookup-list.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SessionService } from './services/session.service';
import { RouteHistoryComponent } from './pages/bus-page/route-history/route-history.component';
import { DeviceHistoryComponent } from './pages/bus-page/device-history/device-history.component';
import { DevicesPageComponent } from './pages/devices-page/devices-page.component';
import { DeviceFormComponent } from './pages/devices-page/device-form/device-form.component';
import { ExtraComponentsModule } from './modules/extra-components.module';
import { HistoryService } from './services/history.service';
import { PublicityService } from './services/publicity.service';
import { AppUserService } from './services/appUser.service';
import { ReportsPageComponent } from './pages/reports-page/reports-page.component';
import { ReportService } from './services/report.service';
import { ReportFormComponent } from './pages/reports-page/report-form/report-form.component';
import { PublicityPageComponent } from './pages/publicity-page/publicity-page.component';
import { PublicityFormComponent } from './pages/publicity-page/publicity-form/publicity-form.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { ActivityHistoryComponent } from './pages/publicity-page/activity-history/activity-history.component';
import { UserSecurityComponent } from './pages/users-page/user-security/user-security.component';
import { PermissionService } from './services/permission.service';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ActiveBusesComponent } from './pages/dashboard-page/active-buses/active-buses.component';
import { CheckerPageComponent } from './pages/checker-page/checker-page.component';

@NgModule({
  declarations: [
    AppComponent, ThemePickerComponent, NavigationMenuComponent,
    ConfirmDialogComponent, Page404Component, FileInputComponent,
    DashboardPageComponent, CheckerPageComponent, DeviceHistoryComponent, RouteHistoryComponent,
    BusPageComponent, BusFormComponent,
    RoutesPageComponent, RouteFormComponent,
    UsersPageComponent, HistoryPageComponent, UserFormComponent,
    LookupComponent, LookupListComponent,
    LoginPageComponent, DevicesPageComponent, DeviceFormComponent,
    ReportsPageComponent, ReportFormComponent,
    PublicityPageComponent, PublicityFormComponent, ActivityHistoryComponent,
    UserSecurityComponent, NoAccessComponent, FileUploadComponent, ActiveBusesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule, ExtraComponentsModule,
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    PipesModule
  ],
  providers: [
    BaseService, ThemeService, DialogService, SessionService,
    LoaderService, NotificationService, WebSocketService,
    MapService, BusService, RouteService, UserService, HistoryService,
    PublicityService, AppUserService, ReportService, PermissionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
