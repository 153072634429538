import { AfterViewInit, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'Rxjs/Operators';
import { DialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { UserService } from 'src/app/services/user.service';
import { UserFormComponent } from './user-form/user-form.component';
import { HistoryService } from 'src/app/services/history.service';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss']
})
export class UsersPageComponent implements OnInit {

  showForm: boolean = false;
  searchValue: string;

  @ViewChild(UserFormComponent) set content(content: UserFormComponent) {
    this.formElement = content;
    this.formValueChanged.emit(this.formElement != null);
  }
  formValueChanged: EventEmitter<any> = new EventEmitter<boolean>();
  formElement: UserFormComponent;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  users: MatTableDataSource<any>;
  displayedColumns: string[] = ['actions', 'username', 'name', 'email', 'isAdmin'];

  constructor(
    private userService: UserService,
    private loader: LoaderService,
    private notification: NotificationService,
    private dialogService: DialogService,
    private sessionService: SessionService,
    private historyService: HistoryService
  ) { }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.loader.showSpinner();

    this.userService.get().then(data => {
      console.log(data);
      this.users = new MatTableDataSource(data);
      this.users.sort = this.sort;
      this.users.paginator = this.paginator;
    }).catch(ex => {
      this.notification.showWarn('Error al cargar listado de usuarios');
      console.log(ex);
    }).finally(() => {
      this.loader.hideSpinner();
    });
  }

  newRecord() {
    this.formValueChanged.pipe(first()).subscribe(f => {
      this.formElement.clearForm();
      this.formElement.enableForm();
    });

    this.showForm = true;
  }

  edit(user) {
    this.formValueChanged.pipe(first()).subscribe(() => {
      this.formElement.populateForm(user);
    });
    this.showForm = true;

  }

  delete(id: string) {
    this.dialogService.openConfirmDialog('Seguro que desea eliminar este autouser?')
      .afterClosed().subscribe(res => {
        if (!res)
          return;

        this.loader.showSpinner();
        this.userService.delete(id).then((data) => {
          this.notification.showSuccess('Usuario eliminado correctamente');
          this.getUsers();
        }).catch(err => {
          this.notification.showWarn(err.message || 'Ocurrio un error al tratar de eliminar el usuario');
        }).finally(() => this.loader.hideSpinner());
      });
  }

  closeForm() {
    this.showForm = false;
  }

  applyFilter() {
    this.users.filter = this.searchValue.trim().toLowerCase();
  }

  clearSearch() {
    this.searchValue = "";
    this.applyFilter();
  }

  clearHistory() { 
    this.historyService.clearHistory();
    this.notification.showSuccess('Solicitud de borrado de historial generada, revisa de nuevo en 5 minutos...');
  }


  downloadDBBackup() {
    this.loader.showSpinner();
    this.sessionService.getDBBackup().then((data: any) => {
      let blob = new Blob([data], { type: 'application/x-tar' });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = "BACKUP-" + new Date().toISOString().split(":").join("").split(".").join("") + ".tar";
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0)
      // let pwa = window.open(url);
      // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      //   alert('Porfavor deshabilite su bloqueo de Pop-ups e intente de nuevo.');
      // }
    }).catch(err => {
      if (err.message)
        this.notification.showWarn(err.message);
      else
        this.notification.showWarn("Ocurrio un error al generar el backup");
    }).finally(() => {
      this.loader.hideSpinner();
    });


  }
}