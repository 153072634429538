import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PublicityService } from 'src/app/services/publicity.service';
import { PublicityFormComponent } from './publicity-form/publicity-form.component';

@Component({
  selector: 'app-publicity-page',
  templateUrl: './publicity-page.component.html',
  styleUrls: ['./publicity-page.component.scss']
})
export class PublicityPageComponent implements OnInit {
  searchValue: string;
  @ViewChild(PublicityFormComponent) formElement: PublicityFormComponent;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  publicityRecords: MatTableDataSource<any>;
  displayedColumns: string[] = ['actions', 'title', 'img', 'active', 'allRoutes'];

  selectedPublicity: any = null;

  constructor(
    private loader: LoaderService,
    private notification: NotificationService,
    private publicityService: PublicityService
  ) { }

  ngOnInit(): void {
    setTimeout(() => this.getpublicityRecords(), 200);
  }

  getpublicityRecords() {
    this.loader.showSpinner();
    this.publicityService.get().then(data => {
      this.publicityRecords = new MatTableDataSource(data);
      this.publicityRecords.sort = this.sort;
      this.publicityRecords.paginator = this.paginator;
    }).catch(err => {
      this.notification.showWarn("Ocurrio un error al cargar registros");
    }).finally(() => {
      this.loader.hideSpinner();
    });
  }

  viewPublicity(publicityData) {
    // this.selectedPublicity = publicityData;
    this.formElement.populateForm(publicityData);
    this.formElement.enableForm();
  }

  applyFilter() {
    this.publicityRecords.filter = this.searchValue.trim().toLowerCase();
  }

  newRecord() {
    this.formElement.clearForm();
    this.formElement.enableForm();
  }

  clearSearch() {
    this.searchValue = "";
    this.applyFilter();
  }
}
