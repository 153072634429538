import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-device-history',
  templateUrl: './device-history.component.html',
  styleUrls: ['./device-history.component.scss']
})
export class DeviceHistoryComponent implements OnInit {

  _deviceHistoryData: MatTableDataSource<any>;
  @Input() set deviceHistoryData(value: Array<any>) {
    this._deviceHistoryData = new MatTableDataSource(value);
    this._deviceHistoryData.sort = this.sort;
    this._deviceHistoryData.paginator = this.paginator;
  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['device', 'user', 'time'];
  private searchValue: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  applyFilter() {
    this._deviceHistoryData.filter = this.searchValue.trim().toLowerCase();
  }

}
