<div class="menu-container" [ngClass]="{ 'sidenav-menu': sidenav }">
  <span class="menu-span">
    <button
      mat-button
      (click)="selectThisBtn('')"
      [ngClass]="{ 'selected-background': selectedBtn == '' }"
    >
      <mat-icon *ngIf="sidenav">home</mat-icon>
      Home
    </button>
  </span>
  <!-- <span class="menu-span">
    <button *ngIf="showChecador" mat-button (click)="selectThisBtn('Checador')"
    [ngClass]="{ 'selected-background': selectedBtn == 'Checador' }">
      <mat-icon *ngIf="sidenav">checker</mat-icon>
      Checador
    </button>
  </span> -->
  <span class="menu-span">
    <button
      *ngIf="showHistorial"
      mat-button
      (click)="selectThisBtn('Historial')"
      [ngClass]="{ 'selected-background': selectedBtn == 'Historial' }"
    >
      <mat-icon *ngIf="sidenav">history</mat-icon>
      History
    </button>
  </span>
  <span class="menu-span">
    <button
      *ngIf="showAutobuses"
      mat-button
      (click)="selectThisBtn('Autobuses')"
      [ngClass]="{ 'selected-background': selectedBtn == 'Autobuses' }"
    >
      <mat-icon *ngIf="sidenav">directions_bus</mat-icon>
      Vehicles
    </button>
  </span>
  <span class="menu-span">
    <button
      *ngIf="showRutas"
      mat-button
      (click)="selectThisBtn('Rutas')"
      [ngClass]="{ 'selected-background': selectedBtn == 'Rutas' }"
    >
      <mat-icon *ngIf="sidenav">timeline</mat-icon>
      Routes
    </button>
  </span>
  <span class="menu-span">
    <button
      *ngIf="showDispositivos"
      mat-button
      (click)="selectThisBtn('Dispositivos')"
      [ngClass]="{ 'selected-background': selectedBtn == 'Dispositivos' }"
    >
      <mat-icon *ngIf="sidenav">share_location</mat-icon>
      GPS Devices
    </button>
  </span>
  <span class="menu-span">
    <button
      *ngIf="showPublicidad"
      mat-button
      (click)="selectThisBtn('Publicidad')"
      [ngClass]="{ 'selected-background': selectedBtn == 'Publicidad' }"
    >
      <mat-icon *ngIf="sidenav">collections</mat-icon>
      Publicidad
    </button>
  </span>
  <span class="menu-span">
    <button
      *ngIf="showReportes"
      mat-button
      (click)="selectThisBtn('Reportes')"
      [ngClass]="{ 'selected-background': selectedBtn == 'Reportes' }"
    >
      <mat-icon *ngIf="sidenav">report</mat-icon>
      Reportes
    </button>
  </span>
  <span class="menu-span">
    <button
      *ngIf="user.isAdmin"
      mat-button
      (click)="selectThisBtn('Usuarios')"
      [ngClass]="{ 'selected-background': selectedBtn == 'Usuarios' }"
    >
      <mat-icon *ngIf="sidenav">admin_panel_settings</mat-icon>
      Security
    </button>
  </span>
</div>
