import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAccessComponent } from '../components/no-access/no-access.component';
import { SessionGuard } from '../guards/session.guard';
import { BusPageComponent } from '../pages/bus-page/bus-page.component';
import { DashboardPageComponent } from '../pages/dashboard-page/dashboard-page.component';
import { DevicesPageComponent } from '../pages/devices-page/devices-page.component';
import { HistoryPageComponent } from '../pages/history-page/history-page.component';
import { LoginPageComponent } from '../pages/login-page/login-page.component';
import { Page404Component } from '../pages/page404/page404.component';
import { PublicityPageComponent } from '../pages/publicity-page/publicity-page.component';
import { ReportsPageComponent } from '../pages/reports-page/reports-page.component';
import { RoutesPageComponent } from '../pages/routes-page/routes-page.component';
import { UsersPageComponent } from '../pages/users-page/users-page.component';
import { CheckerPageComponent } from '../pages/checker-page/checker-page.component';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: '', component: DashboardPageComponent, canActivate: [SessionGuard] },
  { path: 'checador', component: CheckerPageComponent },
  { path: 'autobuses', component: BusPageComponent, canActivate: [SessionGuard] },
  { path: 'rutas', component: RoutesPageComponent, canActivate: [SessionGuard] },
  { path: 'usuarios', component: UsersPageComponent, canActivate: [SessionGuard] },
  { path: 'historial', component: HistoryPageComponent, canActivate: [SessionGuard] },
  { path: 'dispositivos', component: DevicesPageComponent, canActivate: [SessionGuard] },
  { path: 'publicidad', component: PublicityPageComponent, canActivate: [SessionGuard] },
  { path: 'reportes', component: ReportsPageComponent, canActivate: [SessionGuard] },
  { path: 'sinAcceso', component: NoAccessComponent },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes/*, { enableTracing: true }-*/)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
