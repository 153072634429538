<div class="mat-elevation-z8">
  <div class="text-center padding-top">
    <h4 style="font: 500 18px/28px Roboto, 'Helvetica Neue', sans-serif">
      <mat-icon class="fixed-icon">directions_activity</mat-icon>
      Historial De Actividad En Registro
    </h4>
  </div>

  <mat-table matSort [dataSource]="_activityHistoryData">
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Descripción</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Realizado Por</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.user.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="time">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</mat-header-cell>
      <mat-cell *matCellDef="let element">{{
        element.date | formatDate : true
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6">
        <div class="full-width text-center">No activity records...</div>
      </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    <mat-footer-row
      *matFooterRowDef="['noData']"
      [ngClass]="{
        hidden:
          _activityHistoryData != null && _activityHistoryData.data.length > 0
      }"
    >
    </mat-footer-row>
  </mat-table>
  <mat-paginator
    [pageSizeOptions]="[5, 20, 50, 100]"
    [pageSize]="5"
    showFirstLastButton
  ></mat-paginator>
</div>
