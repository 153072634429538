import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BusService } from 'src/app/services/bus.service';
import { HistoryService } from 'src/app/services/history.service';
import { LoaderService } from 'src/app/services/loader.service';
import * as moment from 'moment';

@Component({
  selector: 'app-active-buses',
  templateUrl: './active-buses.component.html',
  styleUrls: ['./active-buses.component.scss']
})
export class ActiveBusesComponent implements OnInit {

  searchValue: string = "";
  allBuses: Array<any> = [];
  displayedColumns: string[] = ['number', 'route', 'lastPing', 'deviceId', 'phoneNumber'];
  busesDS: MatTableDataSource<any>;
  @ViewChild("iSort", { static: false }) iSort: MatSort;

  filterValue: string = 'all';

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData,
    private loader: LoaderService,
    private busService: BusService,
    private historyService: HistoryService,
    @Optional() private dialogRef: MatDialogRef<ActiveBusesComponent>
  ) { }

  ngOnInit(): void {
    this.filterValue = this.dialogData.filterValue;
    setTimeout(() => {
      this.getBusesData();
    });
  }

  filterChanged() {
    this.buildTable();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getBusesData() {
    this.loader.showSpinner();

    Promise.all([
      this.busService.get(),
      this.historyService.get("/lastPingsPerBus")
    ]).then(([allBuses, lastPingsPerBus]) => {
      this.allBuses = allBuses;

      this.allBuses.forEach((ab) => {
        ab.deviceId = ab.device ? String(ab.device.deviceId) : "";
        ab.phoneNumber = ab.device ? String(ab.device.phoneNumber) : "";
        ab.rest = !ab.active || !ab.route || ab.route.name === "DESCANSO";
        let busLastPing = lastPingsPerBus.filter(lppb => ab._id === lppb._id)[0];
        ab._active = !ab.rest && this.busLastPingIsActive(busLastPing);
        ab.inactive = !ab.rest && !ab._active;
        ab.lastPing = busLastPing
          ? moment(new Date(busLastPing.date)).format('YYYY/MM/DD hh:mm:ss A')
          : " Sin registros";
      });

      this.buildTable();

    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      this.loader.hideSpinner();
    });

  }

  busLastPingIsActive(busLastPing: any): boolean {
    if (!busLastPing)
      return false;
    else
      return busLastPing.active;
  }

  buildTable() {
    let tableData = this.allBuses.filter(b => this.filterValue == 'all'
      || (this.filterValue == 'active' && b._active)
      || (this.filterValue == 'rest' && b.rest)
      || (this.filterValue == 'inactive' && b.inactive)
    );

    this.busesDS = new MatTableDataSource(tableData);
    this.busesDS.filterPredicate = this.filterFunc;
    this.busesDS.sort = this.iSort;
  }

  close() {
    this.dialogRef.close();
  }

  clearSearch() {
    this.searchValue = "";
    this.applyFilter();
  }

  applyFilter() {
    this.busesDS.filter = this.searchValue.trim().toLowerCase();
  }

  filterFunc(data: any, filter: string): boolean {
    return filter == ''
      || data.number.indexOf(filter) >= 0
      || (data.route && data.route.name.toLowerCase().indexOf(filter) >= 0)
      || data.deviceId.toLowerCase().indexOf(filter) >= 0
      || data.phoneNumber.toLowerCase().indexOf(filter) >= 0
      || data.lastPing.indexOf(filter) >= 0;
  }

}
