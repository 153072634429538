<button mat-icon-button [mat-menu-trigger-for]="themeMenu" matTooltip="Selecciona un tema!" tabindex="-1">
  <mat-icon>format_color_fill</mat-icon>
</button>

<mat-menu class="docs-theme-picker-menu" #themeMenu="matMenu">
  <mat-grid-list cols="2">
    <mat-grid-tile *ngFor="let theme of themes">
      <div mat-menu-item (click)="setTheme(theme.name)">
        <div class="docs-theme-picker-swatch">
          <mat-icon *ngIf="this.currentTheme === theme" class="docs-theme-chosen-icon">check_circle
          </mat-icon>
          <span class="docs-theme-picker-primary" [style.background]="theme.primary"></span>
        </div>
      </div>
    </mat-grid-tile>

  </mat-grid-list>
</mat-menu>