import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseService {
  constructor(private http_: HttpClient) {
    super(http_);
    this.url = 'report';
  }

  respond(reportId: string, response: string) {
    return new Promise((resolve, reject) => {
      this.custom('respond', { reportId: reportId, response: response }).then(result => {
        resolve(result);
      }).catch(err => {
        reject(err);
      });
    });
  }

}
