import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concatArray'
})
export class ConcatArrayPipe implements PipeTransform {

  transform(array: any, characters: number): any {
    if (typeof array != "object" || !array.length)
      return "";

    let result: string = array.join(", ").substring(0, characters);;
    if (result.length >= characters - 3)
      return result.substring(0, characters - 3) + "...";
    else
      return result;
  }

}
