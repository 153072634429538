<div class="mat-elevation-z8">
  <div class="text-center padding-top">
    <h4
      style="
        font: 500 18px/28px Roboto, 'Helvetica Neue', sans-serif;
        margin: 10px 0;
      "
    >
      <mat-icon class="fixed-icon">lock</mat-icon>
      User Permissions
    </h4>
  </div>

  <mat-table matSort [dataSource]="_userPermissions">
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Permission</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{
        element.permission.description
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="allowed">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Assigned</mat-header-cell
      >
      <mat-cell
        *matCellDef="let element"
        fxLayoutAlign="center"
        style="padding-top: 10px"
      >
        <mat-checkbox
          matInput
          [checked]="element.allowed"
          color="primary"
          (change)="changed(element)"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6">
        <div class="full-width text-center">No Permissions...</div>
      </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    <mat-footer-row
      *matFooterRowDef="['noData']"
      [ngClass]="{
        hidden: _userPermissions != null && _userPermissions.data.length > 0
      }"
    >
    </mat-footer-row>
  </mat-table>
  <mat-paginator
    [pageSizeOptions]="[5, 20, 50, 100]"
    [pageSize]="5"
    showFirstLastButton
  ></mat-paginator>
</div>
