import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as MapBoxGL from 'mapbox-gl';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { Subscription } from 'rxjs';
import { LookupData } from 'src/app/components/lookup/LookupData';
import { RouteService } from 'src/app/services/route.service';
import { BusService } from 'src/app/services/bus.service';
import { MapService } from 'src/app/services/map.service';
import { NotificationService } from 'src/app/services/notification.service';
import * as moment from 'moment';
import { PublicityService } from 'src/app/services/publicity.service';
import { AppUserService } from 'src/app/services/appUser.service';
import { HistoryService } from 'src/app/services/history.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ActiveBusesComponent } from './active-buses/active-buses.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit, OnDestroy {

  routeLookupData: LookupData;
  busLookupData: LookupData;

  activeFilter: string = "";

  socketAllDataSubscription: Subscription;

  socketRouteSubscription: Subscription;
  selectedRoute: any = { name: '' };

  socketBusSubscription: Subscription;
  selectedBus: any = null;

  socketActiveDevicesSubscription: Subscription;
  map: MapBoxGL.Map;
  busMarkers: Map<string, MapBoxGL.Marker> = new Map<string, MapBoxGL.Marker>();

  isMobile: boolean = false;

  dashboardData = {
    total: 0,
    activeBuses: 0,
    inactiveBuses: 0,
    rest: 0,
    activeRoutes: 0,
    todayUsers: 0,
    downloadsToday: 0,
    activePublicity: 0,
    busesLastUpdate: moment(new Date()).format('yyyy/MM/DD HH:mm'),
    lastUpdate: moment(new Date()).format('yyyy/MM/DD HH:mm')
  };
  allBusesData: Array<any> = [];

  constructor(
    private webSocket: WebSocketService,
    private mapService: MapService,
    private routeService: RouteService,
    private busService: BusService,
    private historyService: HistoryService,
    private publicityService: PublicityService,
    private notificationService: NotificationService,
    private appUserService: AppUserService,
    private dialogService: DialogService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.getDashboardData();
    this.initializeMap();
    this.initializeLookups();
    this.initializeSockets();
    this.getBusesData();
  }

  ngOnDestroy(): void {
    this.killSockets();
  }

  getDashboardData() {
    this.dashboardData.downloadsToday = 0;
    this.routeService.find({ active: true }).then((ar: Array<any>) => {
      this.dashboardData.activeRoutes = ar.length || 0;
    });

    Promise.all([this.busService.get(), this.historyService.get("/activeBuses")]).then(([buses, activeBuses]) => {
      if (!buses)
        buses = [];

      this.dashboardData.total = 0;
      this.dashboardData.rest = 0;
      this.dashboardData.activeBuses = 0;

      for (var i = 0; i < buses.length; i++) {
        this.dashboardData.total++;

        if (!buses[i].route || buses[i].route.name === "DESCANSO")
          this.dashboardData.rest++;
        else if (activeBuses.filter(ab => ab._id === buses[i]._id).length > 0)
          this.dashboardData.activeBuses++;
        else
          this.dashboardData.inactiveBuses++;
      }
    });

    this.publicityService.find({ active: true }).then((ap: Array<any>) => {
      this.dashboardData.activePublicity = ap.length || 0;
    });
    this.appUserService.find({ lastConnection: new Date() }).then((au: Array<any>) => {
      this.dashboardData.todayUsers = au.length || 0;
    });
    this.dashboardData.lastUpdate = moment(new Date()).format('yyyy/MM/DD HH:mm');
  }

  initializeMap() {
    (MapBoxGL as any).accessToken = environment.mapBoxKey;

    this.map = new MapBoxGL.Map({
      container: 'map-wrapper', // container id
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [environment.mapCenter.lng, environment.mapCenter.lat], // starting position
      zoom: 14.2 // starting zoom
    });
  }

  initializeLookups() {
    this.routeLookupData = new LookupData({
      valueKey: '_id',
      descriptionKey: 'name',
      label: 'Filter Route',
      placeHolder: 'Filter Route',
      service: RouteService
    });
    this.busLookupData = new LookupData({
      valueKey: '_id',
      descriptionKey: 'number',
      label: 'Filter Device',
      placeHolder: 'Filter Device',
      service: BusService
    });
  }

  initializeSockets() {
    this.webSocket.connect().then(() => {
      // this.socketBusSubscription = this.webSocket.listen('bus').subscribe(data => this.manageBusData(data));
      // this.socketRouteSubscription = this.webSocket.listen('route').subscribe((data) => this.manageRouteData(data));
      // this.socketAllDataSubscription = this.webSocket.listen('allData').subscribe((data) => this.manageBusData(data));
      // this.socketActiveDevicesSubscription = this.webSocket.listen('activeDevices').subscribe((activeBuses: Array<any>) => {
      //   this.dashboardData.activeBuses = activeBuses.length || 0;
      // });
    });
  }

  killSockets() {
    this.webSocket.socket.disconnect();
  }

  endSubscriptions() {
    if (this.socketRouteSubscription && typeof this.socketRouteSubscription.unsubscribe == "function")
      this.socketRouteSubscription.unsubscribe();

    if (this.socketBusSubscription && typeof this.socketBusSubscription.unsubscribe == "function")
      this.socketBusSubscription.unsubscribe();

    if (this.socketAllDataSubscription && typeof this.socketAllDataSubscription.unsubscribe == "function")
      this.socketAllDataSubscription.unsubscribe();

  }

  getBusesData() {
    this.busService.get().then(data => {
      this.allBusesData = data || [];
    }).catch(err => {
      console.log(err);
    })
  }

  viewAllBuses() {
    this.activeFilter = "all";
    this.clearMapData();
    this.webSocket.emit('quitRoom', { room: 'all' }, () => {
      this.endSubscriptions();
      this.socketAllDataSubscription = this.webSocket.listen('allData').subscribe((data) => this.manageBusData(data, false, false));
      this.webSocket.emit('subscribeToAllData', null);
    });
  }

  routeChanged(routeId) {
    this.busLookupData.value = null;
    this.busLookupData.description = "";
    this.activeFilter = "route";
    this.subscribeToRoute(routeId);
  }

  busChanged(busId) {
    this.routeLookupData.value = null;
    this.routeLookupData.description = "";
    this.activeFilter = "bus";
    this.subscribeToBus(busId);
  }

  subscribeToRoute(routeId) {
    debugger;
    this.routeService.find({ _id: routeId }).then(data => {
      if (data == null || !data.length)
        throw 'Invalid data';

      this.busService.find({ route: routeId }).then(assignedBuses => {
        if (!assignedBuses || !assignedBuses.length)
          this.notificationService.showConfirmation("Esta Route no tiene dispositivos asignados");
      }).catch(err => {
        this.notificationService.showConfirmation("Esta Route no tiene dispositivos asignados");
      });
      this.clearMapData();
      this.setSelectedRoute(data[0]);
    }).catch(err => {
      this.notificationService.showWarn('Error al cargar Route');
      console.error(err);
    });

    this.webSocket.emit('quitRoom', { room: 'all' }, () => {
      debugger;
      this.endSubscriptions();
      this.socketRouteSubscription = this.webSocket.listen('route').subscribe((data) => this.manageRouteData(data));
      this.webSocket.emit('subscribeToRoute', { routeId: routeId });
    });
  }

  subscribeToBus(busId) {
    this.busService.find({ _id: busId }).then(data => {
      if (data == null || !data.length)
        throw 'Invalid data';

      this.clearMapData();
      this.selectedBus = data[0];

      this.setSelectedRoute(data[0].route);
      this.historyService.getParams('/busLastPosition/' + data[0]._id).then(busLastPosition => {
        if (busLastPosition == null) {
          this.notificationService.showWarn(`No records from vehicle  "${this.selectedBus.number}"`);
          return;
        }
        this.manageBusData(busLastPosition, true, true);
        this.notificationService.showConfirmation(`Last record of vehicle "${this.selectedBus.number}" in  ${moment(busLastPosition.date).format("YYYY/MM/DD hh:mm:ss A")}`);
      }).catch(err => {
        console.log(err)
      });

    }).catch(err => {
      this.notificationService.showWarn('Error al cargar dispositivo');
      console.error(err);
    });


    this.webSocket.emit('quitRoom', { room: 'all' }, () => {
      this.endSubscriptions();
      this.socketBusSubscription = this.webSocket.listen('bus').subscribe(data => this.manageBusData(data, true, true));
      this.webSocket.emit('subscribeToBus', { busId: busId }), (response) => {
        console.log(response);
      };
    });
  }

  manageRouteData(data) {
    if (!data.bus)
      return null;

    let busMarker = this.busMarkers.get(data.bus);
    data.number = this.allBusesData.filter(b => b._id == data.bus)[0]?.number;

    let busData = this.allBusesData.filter(b => b._id == data.bus)[0];
    if (busData && busData.route)
      data.routeName = busData.route.name;

    let popupText = this.mapService.getBusPopupText(data);
    if (busMarker == null) {
      let busPopup = new MapBoxGL.Popup({ closeOnClick: false, closeButton: false })
        .setOffset([0, -20]).setHTML(popupText);
      let newMarker = this.mapService.addSingleMarker(this.map, [data.lng, data.lat]);
      newMarker.setPopup(busPopup);
      newMarker.togglePopup();
      this.busMarkers.set(data.bus, newMarker);
    }
    else {
      busMarker.getPopup().setHTML(popupText);
      this.mapService.animateMarkerMovement(busMarker, new MapBoxGL.LngLat(data.lng, data.lat), 1000);
    }
  }

  manageBusData(data, flyTo, showData) {
    if (!data.bus)
      return null;

    let busMarker = this.busMarkers.get(data.bus);
    data.number = this.allBusesData.filter(b => b._id == data.bus)[0]?.number;

    let busData = this.allBusesData.filter(b => b._id == data.bus)[0];
    if (busData && busData.route)
      data.routeName = busData.route.name;

    let popupText = this.mapService.getBusPopupText(data);
    if (busMarker == null) {
      let busPopup = new MapBoxGL.Popup({ closeOnClick: false, closeButton: false })
        .setOffset([0, -20]).setHTML(popupText);
      let newMarker = this.mapService.addSingleMarker(this.map, [data.lng, data.lat]);
      newMarker.setPopup(busPopup);
      if (showData)
        newMarker.togglePopup();
      this.busMarkers.set(data.bus, newMarker);
    }
    else {
      if (showData && !busMarker.getPopup().isOpen())
        busMarker.togglePopup();

      busMarker.getPopup().setHTML(popupText);
      this.mapService.animateMarkerMovement(busMarker, new MapBoxGL.LngLat(data.lng, data.lat), 1000);
    }
    if (flyTo)
      this.map.flyTo({ center: new MapBoxGL.LngLat(data.lng, data.lat) });
  }

  clearMapData() {
    this.mapService.clearMap(this.map, Array.from(this.busMarkers?.values() || []).concat(this.selectedRoute?.parsedWayPoints || []));
    this.busMarkers = new Map<string, MapBoxGL.Marker>();
    this.selectedRoute = null;
    this.selectedBus = null;
  }

  setSelectedRoute(route: any) {
    if (route == null)
      return;
    if (typeof route == "string") {
      this.routeService.find({ _id: route }).then(data => {
        if (data == null || !data.length)
          throw 'Invalid data';
        this.setSelectedRoute(data[0]);
      }).catch(err => {
        console.error(err);
      });
    } else if (typeof route == "object") {
      this.selectedRoute = route;
      this.selectedRoute.parsedWayPoints = this.mapService.loadRouteToMap(this.map, this.selectedRoute.wayPoints);
      this.mapService.drawRoute(this.map, this.selectedRoute.parsedWayPoints);
      this.mapService.centerMap(this.map, this.selectedRoute.parsedWayPoints);
    }
  }

  showBusesDetail(filterValue: string) {
    this.dialogService.openComponentDialog(ActiveBusesComponent, { filterValue: filterValue });
  }

  showActiveRoutesDetail() {
    this.notificationService.show("No implementado");
  }

}