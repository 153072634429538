import { Component, OnInit } from '@angular/core';
import { LookupData } from 'src/app/components/lookup/LookupData';
import { BusService } from 'src/app/services/bus.service';
import { RouteService } from 'src/app/services/route.service';
import * as MapBoxGL from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/services/notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { HistoryService } from 'src/app/services/history.service';
import { MapService } from 'src/app/services/map.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-history-page',
  templateUrl: './history-page.component.html',
  styleUrls: ['./history-page.component.scss']
})
export class HistoryPageComponent implements OnInit {

  form: FormGroup = new FormGroup({
    date: new FormControl('', [Validators.required]),
    startTime: new FormControl('', [Validators.required]),
    endTime: new FormControl('', [Validators.required]),
    route: new FormControl('', []),
    bus: new FormControl('', []),
  });

  map: MapBoxGL.Map;
  routeLookupData: LookupData;
  busLookupData: LookupData;
  maxDate: Date = new Date();

  routePlayer: any = {
    isPlaying: false,
    speed: 1000,
    busMarker: null,
    busPopup: null,
    lapIndex: 0,
    markerIndex: 0,
    interval: null
  };

  routeRecord: any = {
    bus: null,
    route: null,
    driver: null,
    distance: 0,
    wayPoints: null,
    rawWayPoints: null,
    lapIndex: -1,
    outOfRouteWayPoints: [],
    lapsData: {
      laps: []
    }
  };

  constructor(
    private notification: NotificationService,
    private historyService: HistoryService,
    private mapService: MapService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.initializeMap();
    this.initializeLookups();
    this.notification.showSuccess("Select Date, Time and Device or Route to load records");
  }

  initializeForm(): void {
    this.form.patchValue({
      date: moment(),
      startTime: "6:00 AM",
      endTime: "8:00 PM",
      bus: null,
      route: null
    });
  }

  initializeLookups(): void {
    this.routeLookupData = new LookupData({
      valueKey: '_id',
      descriptionKey: 'name',
      label: 'Filtrar Por Ruta',
      placeHolder: 'Filtrar Por Ruta',
      service: RouteService
    });

    this.busLookupData = new LookupData({
      valueKey: '_id',
      descriptionKey: 'number',
      label: 'Filter By Device',
      placeHolder: 'Filter By Device',
      service: BusService
    });
  }

  initializeMap(): void {
    (MapBoxGL as any).accessToken = environment.mapBoxKey;

    this.map = new MapBoxGL.Map({
      container: 'map-wrapper-historico', // container id
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [environment.mapCenter.lng, environment.mapCenter.lat], // starting position
      zoom: 14.2 // starting zoom
    });
    this.map.on('load', () => {
      this.initializeBusMarker();
      // this.search();
    })
  }

  search(): void {
    let searchParams = this.getFormValues();
    if (searchParams.bus == null) {
      this.notification.showWarn("It is necessary to select a Device");
      return;
    }
    this.loader.showSpinner();
    this.historyService.find(searchParams).then(data => {
      if (!data || !data.length) {
        this.notification.showWarn("No records");
        return;
      }
      data.forEach(d => {
        if (d.date != null)
          d.date = new Date(d.date)
      });

      this.updateInfoContainerData(data);
      this.initializeRouteRecord(data);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      this.loader.hideSpinner();
    });
  }

  routeChanged(selectedRoute: any): void {
    this.form.patchValue({ route: selectedRoute });
  }

  busChanged(selectedBus: any): void {
    this.form.patchValue({ bus: selectedBus });
  }

  getFormValues(): any {
    let rawForm = this.form.value;
    let startHour = parseInt(rawForm.startTime.split(":")[0]),
      startMinutes = parseInt(rawForm.startTime.split(":")[1].split(" ")[0]),
      endHour = parseInt(rawForm.endTime.split(":")[0]),
      endMinutes = parseInt(rawForm.endTime.split(":")[1].split(" ")[0]);

    if (rawForm.startTime.indexOf("PM") >= 0)
      startHour += 12;
    if (rawForm.endTime.indexOf("PM") >= 0)
      endHour += 12;

    let values = {
      startDate: new Date(rawForm.date.year(), rawForm.date.month(), rawForm.date.date(), startHour, startMinutes, 0).getTime(),
      endDate: new Date(rawForm.date.year(), rawForm.date.month(), rawForm.date.date(), endHour, endMinutes, 0).getTime(),
      // route: rawForm.route,
      bus: rawForm.bus
    };

    return values;
  }

  clearMap() {
    this.mapService.clearMap(this.map, (
      this.routeRecord.wayPoints as Array<MapBoxGL.Marker> || [])
      .concat(this.routePlayer.busMarker as MapBoxGL.Marker || [])
      .concat(this.routeRecord.outOfRouteWayPoints as Array<MapBoxGL.Marker> || [])
    );
    this.routeRecord.outOfRouteWayPoints = [];
    this.routePlayer.isPlaying = false;
    this.routePlayer.speed = 1000;
    this.routePlayer.busMarker = null;
    this.routePlayer.busPopup = null;
    this.routePlayer.lapIndex = 0;
    this.routePlayer.markerIndex = 0;
    this.routePlayer.interval = null;
  }

  initializeRouteRecord(traveledData: Array<any>) {
    this.routeRecord.lapsData = this.getLapsData(traveledData);
    this.routeRecord.route = this.routeRecord.lapsData.route;
    this.initializeLapRecord(0);
  }

  getLapOutOfRoutes(lap: Array<any>, route: Array<any>): Array<MapBoxGL.LngLatLike> {
    if (route == null)
      return [];

    let outOfRoutes = [];
    let parsedLap = (lap.map(l => {
      return { lng: l.lng, lat: l.lat }
    }) as Array<MapBoxGL.LngLat>);

    let parsedRoute = (route.map(r => {
      return { lng: r.lng, lat: r.lat }
    }) as Array<MapBoxGL.LngLat>);

    parsedLap.forEach(wp => {
      if (!this.mapService.isInsideRoute(wp, parsedRoute))
        outOfRoutes.push(wp);
    });

    return outOfRoutes;
  }

  initializeLapRecord(lapIndex: number) {
    this.pauseRoute();
    this.clearMap();

    if (lapIndex < 0)
      lapIndex = this.routeRecord.lapsData.laps.length - 1;
    else if (lapIndex >= this.routeRecord.lapsData.laps.length)
      lapIndex = 0;

    if (this.routeRecord.route != null)
      this.mapService.drawRawRoute(this.map, this.routeRecord.route.wayPoints, this.mapService.styles.definedRoute);

    this.routeRecord.lapIndex = lapIndex;
    this.routeRecord.wayPoints = this.mapService.loadRouteToMap(this.map, this.routeRecord.lapsData.laps[this.routeRecord.lapIndex]);
    this.routeRecord.rawWayPoints = this.routeRecord.lapsData.laps[this.routeRecord.lapIndex];
    this.mapService.centerMap(this.map, this.routeRecord.wayPoints);
    this.routePlayer.markerIndex = 9999;
    this.moveBusToNextPos(true);
    this.mapService.flyTo(this.map, this.routePlayer.busMarker.getLngLat());

    let lapOutOfRoutes = this.getLapOutOfRoutes(this.routeRecord.lapsData.laps[this.routeRecord.lapIndex], this.routeRecord.route?.wayPoints);
    lapOutOfRoutes.forEach(wp => this.routeRecord.outOfRouteWayPoints.push(this.mapService.addOutOfRouteMarker(this.map, wp)));
  }

  updateInfoContainerData(data) {
    if (data == null || !data.length) {
      this.clearInfoContainer();
      return;
    }

    this.routeRecord.bus = data.filter(rw => rw.bus != null)[0]?.bus;
    this.routeRecord.route = data.filter(rw => rw.route != null)[0]?.route;
    this.routeRecord.driver = data.filter(rw => rw.driver != null)[0]?.driver;
    this.routeRecord.distance = this.mapService.calculateRouteDistance(data);
  }

  clearInfoContainer() {
    this.routeRecord.bus = null;
    this.routeRecord.route = null;
    this.routeRecord.driver = null;
    this.routeRecord.distance = 0;
    this.routeRecord.outOfRouteWayPoints = [];
  }

  moveBusToPrevPos(pause: Boolean = true) {
    if (pause)
      this.pauseRoute();

    this.routePlayer.markerIndex = (this.routePlayer.markerIndex > 0)
      ? --this.routePlayer.markerIndex
      : this.routeRecord.wayPoints.length - 1;
    if (this.routePlayer.markerIndex == this.routeRecord.wayPoints.length - 1)
      this.routePlayer.busMarker.setLngLat(this.routeRecord.wayPoints[this.routeRecord.wayPoints.length - 1].getLngLat());
    else
      this.mapService.animateMarkerMovement(this.routePlayer.busMarker, this.routeRecord.wayPoints[this.routePlayer.markerIndex].getLngLat(), this.routePlayer.speed);

    this.updateBusMarkerPopupData();
  }

  moveBusToNextPos(pause: Boolean = true) {
    if (this.routePlayer.busMarker == null)
      this.initializeBusMarker();

    if (pause)
      this.pauseRoute();

    this.routePlayer.markerIndex = (this.routePlayer.markerIndex < (this.routeRecord.wayPoints.length - 1))
      ? ++this.routePlayer.markerIndex
      : 0;
    if (this.routePlayer.markerIndex == 0)
      this.routePlayer.busMarker.setLngLat(this.routeRecord.wayPoints[0].getLngLat());
    else
      this.mapService.animateMarkerMovement(this.routePlayer.busMarker, this.routeRecord.wayPoints[this.routePlayer.markerIndex].getLngLat(), this.routePlayer.speed);

    this.updateBusMarkerPopupData();
  }

  playRoute() {
    this.routePlayer.isPlaying = true;
    this.routePlayer.interval = setInterval(() => {
      this.moveBusToNextPos(false);
    }, this.routePlayer.speed);
  }

  pauseRoute() {
    this.routePlayer.isPlaying = false;
    clearInterval(this.routePlayer.interval);
  }

  updateBusMarkerPopupData() {
    // let time = moment(this.routeRecord.rawWayPoints[this.routePlayer.markerIndex].date).format('hh:mm:ss A');
    this.routePlayer.busMarker.getPopup().setHTML(this.mapService.getBusPopupText(this.routeRecord.rawWayPoints[this.routePlayer.markerIndex]));
  }

  initializeBusMarker() {
    let el: HTMLElement = document.createElement('div');
    el.className = 'bus-marker';

    this.routePlayer.busPopup = new MapBoxGL.Popup({ closeOnClick: false, closeButton: false })
      .setOffset([0, -20])

    this.routePlayer.busMarker = new MapBoxGL.Marker(el)
      .setLngLat([0, 0])
      .addTo(this.map)
      .setPopup(this.routePlayer.busPopup);

    this.routePlayer.busMarker.togglePopup();
  }

  getLapsData(wayPoints) {
    let lapsData = {
      route: null,//ok
      laps: [],
      fastestLap: 0,
      slowestLap: 0,
      avgLap: 0,
      fastestSpeed: 0,
      slowestSpeed: 0,
      avgSpeed: 0
    };

    if (!wayPoints || !wayPoints.length)
      return lapsData;

    let routeFirstWayPoint = null;
    let totalSpeed = 0;
    let validSpeedRecords = 0;

    lapsData.route = wayPoints.filter(wp => wp.route != null).length > 0 ? wayPoints.filter(wp => wp.route != null)[0].route : null;

    routeFirstWayPoint = lapsData.route != null ? lapsData.route.wayPoints[0] : null;
    //if no route assigned then
    if (!lapsData.route) {
      lapsData.laps.push([]);
      for (var i = 0; i < wayPoints.length; i++) {
        if (i == wayPoints.length - 1 || wayPoints.speed > 0 || (i < wayPoints.length - 1 && this.mapService.calculateDistance(wayPoints[i].lat, wayPoints[i].lng, wayPoints[i + 1].lat, wayPoints[i + 1].lng) > 0.01))
          lapsData.laps[0].push(wayPoints[i]);
      }

      lapsData.fastestLap = this.getHoursDifferenceInWayPoints(wayPoints);
      lapsData.slowestLap = lapsData.fastestLap;
      lapsData.avgLap = lapsData.fastestLap;
      lapsData.fastestSpeed = this.getFastestSpeed(wayPoints);
      lapsData.slowestSpeed = this.getSlowestSpeed(wayPoints);
      lapsData.avgSpeed = this.getAvgSpeed(wayPoints);
      return lapsData;
    }

    //if route assigned then
    let actualLap = [];
    let lapTime = 0;

    for (var i = 0; i < wayPoints.length; i++) {

      if (wayPoints[i].speed > 2.7) {//mayor a 5 kilometros por hora
        totalSpeed += wayPoints[i].speed;
        validSpeedRecords++;
        if (wayPoints[i].speed < lapsData.slowestSpeed)
          lapsData.slowestSpeed = wayPoints[i].speed;
        if (wayPoints[i].speed > lapsData.fastestSpeed)
          lapsData.fastestSpeed = wayPoints[i].speed;
      }

      if ((actualLap.length == 0 || i + 1 == wayPoints.length) || (actualLap.length > 0 && this.mapService.calculateDistance(wayPoints[i].lat, wayPoints[i].lng, actualLap[actualLap.length - 1].lat, actualLap[actualLap.length - 1].lng) > 0.01)) {
        actualLap.push(wayPoints[i]);

        if (actualLap.length > 0 && this.mapService.calculateDistance(wayPoints[i].lat, wayPoints[i].lng, routeFirstWayPoint.lat, routeFirstWayPoint.lng) <= 0.015) {
          lapsData.laps.push(actualLap);
          lapTime = this.getDifferenceInHours(new Date(actualLap[0].date), new Date(actualLap[actualLap.length - 1]));

          if (lapTime > lapsData.fastestLap)
            lapsData.fastestLap = lapTime;

          if (lapTime > 0 && lapTime < lapsData.slowestLap)
            lapsData.slowestLap = lapTime;

          actualLap = [];
        }
      }
      lapsData.avgLap = this.getDifferenceInHours(new Date(wayPoints[0].date), new Date(wayPoints[wayPoints.length - 1])) / lapsData.laps.length;
    }
    //add extra points to final lap
    if (actualLap.length)
      lapsData.laps.push(actualLap);

    if (validSpeedRecords)
      lapsData.avgSpeed = totalSpeed / validSpeedRecords;

    return lapsData;
  }

  getHoursDifferenceInWayPoints(wayPoints: Array<any>) {
    return Math.abs(new Date(wayPoints[0].date).getTime() - new Date(wayPoints[wayPoints.length - 1].date).getTime()) / 36e5;
  }

  getDifferenceInHours(startTime: Date, endTime: Date) {
    return Math.abs(endTime.getTime() - startTime.getTime()) / 36e5;
  }

  getFastestSpeed(wayPoints: Array<any>): number {
    if (!wayPoints || !wayPoints.length)
      return 0;
    let arrayCopy = JSON.parse(JSON.stringify(wayPoints));
    return this.mapService.knotsToKm(arrayCopy.sort(function (a, b) {
      if (a.speed < b.speed)
        return 1;
      else if (a.speed > b.speed)
        return -1;
      else
        return 0;
    })[0]);
  }

  getSlowestSpeed(wayPoints: Array<any>): number {
    if (!wayPoints || !wayPoints.length)
      return 0;
    let arrayCopy = JSON.parse(JSON.stringify(wayPoints));
    return this.mapService.knotsToKm(arrayCopy.sort(function (a, b) {
      if (a.speed < b.speed)
        return 1;
      else if (a.speed > b.speed)
        return -1;
      else
        return 0;
    })[arrayCopy.length - 1]);
  }

  getAvgSpeed(wayPoints: Array<any>): number {
    if (!wayPoints || !wayPoints.length)
      return 0;

    var total = 0;
    wayPoints.forEach(knot => {
      if (knot < 2.8)
        return;
      total += knot;
    });

    return this.mapService.knotsToKm(total / wayPoints.length);
  }
}