<div class="page-container">
  <mat-card *ngIf="showForm">
    <app-user-form
      (closedForm)="closeForm()"
      (dataSubmitted)="getUsers()"
    ></app-user-form>
    <br />
  </mat-card>

  <div class="search-div" fxLayout="row" fxLayoutGap="5px">
    <mat-form-field class="search-form-field">
      <button
        mat-button
        matPrefix
        mat-icon-button
        aria-label="Delete"
        (click)="clearSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <input
        matInput
        [(ngModel)]="searchValue"
        placeholder="Search..."
        autocomplete="off"
        type="text"
        (keyup)="applyFilter()"
      />
    </mat-form-field>
    <button
      class="top-right-button"
      color="primary"
      mat-raised-button
      fxFlex="80px"
      (click)="newRecord()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <!-- <button
      class="top-right-button"
      color="accent"
      mat-raised-button
      fxFlex="195px"
      (click)="downloadDBBackup()"
      disabled
    >
      <mat-icon>download</mat-icon>
      Descargar Respaldo
    </button> -->

    <button
      class="top-right-button"
      color="accent"
      mat-raised-button
      fxFlex="195px"
      (click)="clearHistory()"
      disabled
    >
      <mat-icon>clear</mat-icon>
      Clear History
    </button>
  </div>

  <div class="mat-elevation-z8">
    <mat-table [dataSource]="users" matSort>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
          Actions
          <!-- <h3>Autousers</h3> -->
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button color="accent" (click)="edit(element)">
            <mat-icon>launch</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="delete(element._id)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >User</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.username }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Name</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Email</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="isAdmin">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Administrator</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.isAdmin" color="primary">check_box</mat-icon>
          <mat-icon *ngIf="!element.isAdmin">check_box_outline_blank</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="noData">
        <mat-footer-cell *matFooterCellDef colspan="6">
          <div class="full-width text-center">No Records...</div>
        </mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      <mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{ hidden: users != null && users.data.length > 0 }"
      >
      </mat-footer-row>
    </mat-table>
    <mat-paginator
      [pageSizeOptions]="[15, 30, 50, 100]"
      [pageSize]="15"
      showFirstLastButton
    ></mat-paginator>
  </div>
</div>
