import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { DeviceService } from 'src/app/services/device.service';
import { DialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DeviceFormComponent } from './device-form/device-form.component';

@Component({
  selector: 'app-devices-page',
  templateUrl: './devices-page.component.html',
  styleUrls: ['./devices-page.component.scss']
})
export class DevicesPageComponent implements OnInit {

  showForm: boolean = false;
  searchValue: string;

  @ViewChild(DeviceFormComponent) set content(content: DeviceFormComponent) {
    this.formElement = content;
    setTimeout(() => {
      this.formValueChanged.emit(this.formElement != null);
    }, 100);
  }
  formValueChanged: EventEmitter<any> = new EventEmitter<boolean>();
  formElement: DeviceFormComponent;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  devices: MatTableDataSource<any>;
  displayedColumns: string[] = ['actions', 'deviceId', 'phoneNumber', 'description', 'active'];

  constructor(
    private deviceService: DeviceService,
    private loader: LoaderService,
    private notification: NotificationService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.getDevices();
  }

  getDevices() {
    this.loader.showSpinner();

    this.deviceService.get().then(data => {
      this.devices = new MatTableDataSource(data);
      this.devices.sort = this.sort;
      this.devices.paginator = this.paginator;
    }).catch(ex => {
      this.notification.showWarn('Error al cargar listado de dispositivos GPS');
    }).finally(() => {
      this.loader.hideSpinner();
    });
  }

  newRecord() {
    this.formValueChanged.pipe(first()).subscribe(f => {
      this.formElement.clearForm();
      this.formElement.enableForm();
    });

    this.showForm = true;
  }

  edit(device) {
    if (this.formElement)
      this.formElement.populateForm(device)
    else
      this.formValueChanged.pipe(first()).subscribe(() => {
        this.formElement.populateForm(device);
      });
    this.showForm = true;
  }

  delete(id: string) {
    this.dialogService.openConfirmDialog('Seguro que desea eliminar este autouser?')
      .afterClosed().subscribe(res => {
        if (!res)
          return;

        this.loader.showSpinner();
        this.deviceService.delete(id).then((data) => {
          this.notification.showSuccess('Dispositivo GPS eliminado correctamente');
          this.getDevices();
        }).catch(err => {
          this.notification.showWarn(err.message || 'Ocurrio un error al tratar de eliminar el dispositivo GPS');
        }).finally(() => this.loader.hideSpinner());
      });
  }

  closeForm() {
    this.showForm = false;
  }

  applyFilter() {
    this.devices.filter = this.searchValue.trim().toLowerCase();
  }

  clearSearch() {
    this.searchValue = "";
    this.applyFilter();
  }

}
