import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-activity-history',
  templateUrl: './activity-history.component.html',
  styleUrls: ['./activity-history.component.scss']
})
export class ActivityHistoryComponent implements OnInit {

  _activityHistoryData: MatTableDataSource<any>;
  @Input() set activityHistoryData(value: Array<any>) {
    this._activityHistoryData = new MatTableDataSource(value);
    this._activityHistoryData.sort = this.sort;
    this._activityHistoryData.paginator = this.paginator;
  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['description', 'user', 'time'];
  private searchValue: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  applyFilter() {
    this._activityHistoryData.filter = this.searchValue.trim().toLowerCase();
  }

}
