import { Injectable } from '@angular/core';
import * as MapBoxGL from 'mapbox-gl';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Injectable()
export class MapService {
  activeLayers: Array<string> = [];
  styles: any = {
    routeRecord: {
      id: 'routeRecord',
      type: 'line',
      source: 'routeRecord',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': 'rgba(0,0,0,0.6)',
        'line-width': 4
      }
    },
    definedRoute: {
      id: 'definedRoute',
      type: 'line',
      source: 'definedRoute',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': 'rgba(46, 204, 113, 0.5)',
        'line-width': 12
      }
    }
  }

  constructor() { }

  findRouteCenter(rawWayPoints: Array<MapBoxGL.Marker>): MapBoxGL.LngLatLike {
    let biggestDistance = 0,
      routeCenter = { lat: environment.mapCenter.lat, lng: environment.mapCenter.lng };

    if (!rawWayPoints || !rawWayPoints.length)
      return { lat: environment.mapCenter.lat, lng: environment.mapCenter.lng };

    let wayPoints = rawWayPoints.map(w => w.getLngLat());
    for (let i = 0; i < wayPoints.length; i++) {
      for (let j = 0; j < wayPoints.length; j++) {
        let newDistance = this.calculateDistance(wayPoints[i].lat, wayPoints[i].lng, wayPoints[j].lat, wayPoints[j].lng);
        if (newDistance > biggestDistance) {
          biggestDistance = newDistance;
          routeCenter = { lat: (wayPoints[i].lat + wayPoints[j].lat) / 2, lng: (wayPoints[i].lng + wayPoints[j].lng) / 2 };
        }
      }
    }

    return [routeCenter.lng, routeCenter.lat];
  }

  addSingleMarker(map: MapBoxGL.Map, lngLat: MapBoxGL.LngLatLike) {
    let el: HTMLElement = document.createElement('div');
    el.className = 'bus-marker';

    let newMarker = new MapBoxGL.Marker(el)
      .setLngLat(lngLat)
      .addTo(map)

    return newMarker;
  }

  addOutOfRouteMarker(map: MapBoxGL.Map, lngLat: MapBoxGL.LngLatLike) {
    let el: HTMLElement = document.createElement('div');
    el.className = 'outofroute-marker';

    let newMarker = new MapBoxGL.Marker(el)
      .setLngLat(lngLat)
      .addTo(map)

    return newMarker;
  }

  calculateRouteDistance(wayPoints: Array<any>) {
    let length = 0;
    if (wayPoints == null || wayPoints.length == 0)
      return length;

    for (let x = 0; x < wayPoints.length - 1; x++) {
      let wayPoint1 = typeof wayPoints[x].getLngLat == "function" ? wayPoints[x].getLngLat() : { lng: wayPoints[x].lng, lat: wayPoints[x].lat }
      let wayPoint2 = typeof wayPoints[x + 1].getLngLat == "function" ? wayPoints[x + 1].getLngLat() : { lng: wayPoints[x + 1].lng, lat: wayPoints[x + 1].lat }
      length += this.calculateDistance(wayPoint1.lat, wayPoint1.lng, wayPoint2.lat, wayPoint2.lng);
    }

    return length | 0;
  }

  calculateDistance(lat1: number, lng1: number, lat2: number, lng2: number): number {
    var R = 6371; // km 

    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lng2 - lng1);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  loadRouteToMap(map: MapBoxGL.Map, waypoints: Array<any>): Array<MapBoxGL.Marker> {
    let markers = new Array<MapBoxGL.Marker>();

    waypoints.forEach(wp => {
      let el: HTMLElement = document.createElement('div');
      el.className = 'small-waypoint-marker';

      let marker = new MapBoxGL.Marker(el)
        .setLngLat([wp.lng, wp.lat])
        .addTo(map);

      markers.push(marker);
    });
    return markers;
  }

  centerMap(map: MapBoxGL.Map, waypoints: Array<MapBoxGL.Marker>) {
    if (waypoints.length > 1)
      map.flyTo({ center: this.findRouteCenter(waypoints) });
    else if (waypoints.length == 1)
      map.flyTo({ center: waypoints[0].getLngLat() });
    else
      return;
  }

  flyTo(map: MapBoxGL.Map, wayPoint: MapBoxGL.LngLat) {
    map.flyTo({ center: wayPoint });
  }

  drawRoute(map: MapBoxGL.Map, waypoints: Array<MapBoxGL.Marker>, layerConfig: any = null) {
    if (layerConfig == null)
      layerConfig = this.styles.definedRoute;

    map.addSource(layerConfig.id, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: waypoints.map(w => [w.getLngLat().lng, w.getLngLat().lat])
        }
      }
    });
    map.addLayer(layerConfig);
    this.activeLayers.push(layerConfig.id);
  }

  drawRawRoute(map: MapBoxGL.Map, waypoints: Array<any>, layerConfig: any = null) {
    if (layerConfig == null)
      layerConfig = this.styles.definedRoute;

    map.addSource(layerConfig.id, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: waypoints.map(w => [w.lng, w.lat])
        }
      }
    });
    map.addLayer(layerConfig);
    this.activeLayers.push(layerConfig.id);
  }

  clearMap(map: MapBoxGL.Map, markers: Array<MapBoxGL.Marker> = null) {
    this.activeLayers.forEach(al => {
      if (typeof map.getLayer(al) != 'undefined')
        map.removeLayer(al).removeSource(al);
    });
    this.activeLayers = [];
    if (markers)
      this.clearMarkers(markers);
  }

  clearMarkers(markers: Array<MapBoxGL.Marker>) {
    if (markers == null || !markers.length)
      return;
    markers.forEach(m => {
      m.remove();
    });
  }

  animateMarkerMovement(marker: MapBoxGL.Marker, newPos: MapBoxGL.LngLat, ms: number) {
    let wayPoints: Array<MapBoxGL.LngLat> = this.getWayPointsFromAToB(marker.getLngLat(), newPos, ms);
    let i = 0;
    let move = () => {
      marker.setLngLat(wayPoints[i++])
      if (!(i >= wayPoints.length - 1))
        setTimeout(() => { move(); }, 25);
    };
    move();
    // let interval = setInterval(() => {
    //   marker.setLngLat(wayPoints[i++])
    //   if (i >= wayPoints.length - 1)
    //     clearInterval(interval);
    // }, 25);
  }

  private getWayPointsFromAToB(a: MapBoxGL.LngLat, b: MapBoxGL.LngLat, ms: number): Array<MapBoxGL.LngLat> {
    let latDistance = a.lat - b.lat;
    let lngDistance = a.lng - b.lng;
    let totalFrames = ms / 25;//should move this number of times
    let wayPoints = [];

    for (let i = 1; i <= totalFrames; i++) {//get way points
      wayPoints.push({
        lng: a.lng - ((lngDistance / totalFrames) * i),
        lat: a.lat - ((latDistance / totalFrames) * i)
      });
    }

    //pop last one and push point B in order to get to the exact position of point B 
    //instead of a failed calculated final position
    // wayPoints.pop();
    wayPoints.push(b);
    return wayPoints;
  }

  isInsideRoute(pos: MapBoxGL.LngLat, route: Array<MapBoxGL.LngLat>): boolean {
    let line: Array<MapBoxGL.LngLat> = [];
    let nextWayPoint = null;

    for (let i = 0; i < route.length; i++) {
      nextWayPoint = (i < route.length - 1) ? route[i + 1] : route[0];
      line = this.getWayPointsFromAToBByDistance(route[i], nextWayPoint);
      for (let j = 0; j < line.length; j++) {
        if (this.calculateDistance(pos.lat, pos.lng, line[j].lat, line[j].lng) <= 0.025) {
          return true;
        }
      }
    }

    return false;
  }

  getOutOfRoutePoints(traveledRoute: Array<MapBoxGL.LngLat>, definedRoute: Array<MapBoxGL.LngLat>) {
    return traveledRoute.filter(wp => !this.isInsideRoute(wp, definedRoute));
  }

  getMiddlePoint(a: MapBoxGL.Marker, b: MapBoxGL.Marker): MapBoxGL.LngLat {
    let middleLng = (a.getLngLat().lng + b.getLngLat().lng) / 2;
    let middleLat = (a.getLngLat().lat + b.getLngLat().lat) / 2;
    return new MapBoxGL.LngLat(middleLng, middleLat);
  }

  private getWayPointsFromAToBByDistance(a: MapBoxGL.LngLat, b: MapBoxGL.LngLat): Array<MapBoxGL.LngLat> {
    let distance = this.calculateDistance(a.lat, a.lng, b.lat, b.lng);
    let latDistance = a.lat - b.lat;
    let lngDistance = a.lng - b.lng;
    let totalPoints = distance * 1000 / 10;

    let wayPoints = [];

    for (let i = 1; i <= totalPoints; i++) {//get way points
      wayPoints.push({
        lng: a.lng - ((lngDistance / totalPoints) * i),
        lat: a.lat - ((latDistance / totalPoints) * i)
      });
    }

    //pop last one and push point B in order to get to the exact position of point B 
    //instead of a failed calculated final position
    wayPoints.pop();
    wayPoints.push(b);
    return wayPoints;
  }

  private toRad(n: number) {
    return n * Math.PI / 180;
  }

  private toDeg(n: number) {

  }

  knotsToKm(knots: number): number {
    if (knots == null || knots == undefined || isNaN(knots))
      knots = 0;

    return (knots * 1.852);
  }

  getBusPopupText(busData: any): string {
    let title = '';
    if (busData.number)
      title = `<b>Vehicle #${busData.number}</b><br>`;
    let date = moment(busData.date).format('YYYY/MM/DD');
    let time = moment(busData.date).format('hh:mm:ss A');
    let speed = this.knotsToKm(busData.speed).toFixed(2) + " km/h";
    let route = '';
    if (busData.routeName)
      route = `<b>Route: </b>${busData.routeName}`;
    return `${title}<b>Date: </b>${date}<br><b>Time: </b>${time}<br><b>Speed: </b>${speed}<br>${route}`;
  }

}