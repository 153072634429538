<div class="history-container">
  <form #userForm [formGroup]="form" (submit)="search()">
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end">
      <mat-form-field fxFlex="10%" appearance="fill" fxFlex="100%">
        <mat-label>Date</mat-label>
        <input
          formControlName="date"
          matInput
          (click)="picker.open()"
          [matDatepicker]="picker"
          [max]="maxDate"
          readonly
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field fxFlex="10%" appearance="fill" fxFlex="100%">
        <mat-label>Start Time</mat-label>
        <input
          formControlName="startTime"
          matInput
          [ngxTimepicker]="startTimepicker"
          readonly
        />
        <mat-icon (click)="startTimepicker.open()" matSuffix>schedule</mat-icon>
        <ngx-material-timepicker #startTimepicker></ngx-material-timepicker>
      </mat-form-field>

      <mat-form-field fxFlex="10%" appearance="fill" fxFlex="100%">
        <mat-label>End Time</mat-label>
        <input
          formControlName="endTime"
          matInput
          [ngxTimepicker]="endTimepicker"
          formControlName="endTime"
          readonly
        />
        <mat-icon (click)="endTimepicker.open()" matSuffix>schedule</mat-icon>
        <ngx-material-timepicker
          #endTimepicker
          toggleIcon="endIcon"
        ></ngx-material-timepicker>
      </mat-form-field>

      <!-- <app-lookup fxFlex="20%" [lookupData]="routeLookupData" [appearance]="'fill'" (onChange)="routeChanged($event)">
      </app-lookup> -->

      <app-lookup
        fxFlex="20%"
        [lookupData]="busLookupData"
        [appearance]="'fill'"
        (onChange)="busChanged($event)"
      >
      </app-lookup>

      <div>
        <button class="search-btn" mat-raised-button color="primary">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </form>

  <div id="results-container" fxLayout="row" fxLayoutGap="20px">
    <div id="info-container" fxFlex="300px">
      <!-- <mat-label>Resultados de la busqueda</mat-label> -->

      <mat-list>
        <div mat-subheader>Laps</div>
        <mat-list-item>
          <div class="full-width" style="padding: 10px 0">
            <div fxLayout="row" class="padding">
              <div fxFlex="33%" fxLayoutAlign="end">
                <button
                  mat-mini-fab
                  color="default"
                  (click)="initializeLapRecord(routeRecord.lapIndex - 1)"
                >
                  <mat-icon>skip_previous</mat-icon>
                </button>
              </div>
              <div
                fxFlex="33%"
                fxLayoutAlign="center"
                style="padding-top: 10px"
              >
                {{ routeRecord.lapIndex + 1 }}/{{
                  routeRecord.lapsData.laps.length
                }}
              </div>

              <div fxFlex="33%" fxLayoutAlign="start">
                <button
                  mat-mini-fab
                  color="default"
                  (click)="initializeLapRecord(routeRecord.lapIndex + 1)"
                >
                  <mat-icon>skip_next</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>

      <mat-list>
        <div mat-subheader>Player</div>
        <mat-list-item>
          <div class="full-width" style="padding: 10px 0">
            <div fxLayout="row" class="padding">
              <div fxFlex="33%" fxLayoutAlign="end">
                <button mat-mini-fab (click)="moveBusToPrevPos()">
                  <mat-icon>skip_previous</mat-icon>
                </button>
              </div>
              <div fxFlex="33%" fxLayoutAlign="center">
                <button
                  *ngIf="!routePlayer.isPlaying"
                  mat-mini-fab
                  (click)="playRoute()"
                >
                  <mat-icon>play_arrow</mat-icon>
                </button>
                <button
                  *ngIf="routePlayer.isPlaying"
                  mat-mini-fab
                  (click)="pauseRoute()"
                >
                  <mat-icon>pause</mat-icon>
                </button>
              </div>
              <div fxFlex="33%" fxLayoutAlign="start">
                <button mat-mini-fab (click)="moveBusToNextPos()">
                  <mat-icon>skip_next</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>

      <mat-list>
        <div mat-subheader>Device</div>
        <mat-list-item>
          <mat-icon mat-list-icon>share_location</mat-icon>
          <div mat-line>
            {{
              routeRecord.bus != null ? routeRecord.bus.name : "Unidentified"
            }}
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>

      <mat-list>
        <div mat-subheader>Route</div>
        <mat-list-item>
          <mat-icon mat-list-icon>timeline</mat-icon>
          <div mat-line>
            {{
              routeRecord.route != null
                ? routeRecord.route.name
                : "Unidentified"
            }}
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>

      <mat-list>
        <div mat-subheader>Driver</div>
        <mat-list-item>
          <mat-icon mat-list-icon>airline_seat_recline_extra</mat-icon>
          <div mat-line>
            {{
              routeRecord.driver != null
                ? routeRecord.driver.name
                : "Unidentified"
            }}
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>

      <mat-list>
        <div mat-subheader>Traveled Distance</div>
        <mat-list-item>
          <mat-icon mat-list-icon>show_chart</mat-icon>
          <div mat-line>{{ routeRecord.distance | number : "1.2-2" }} km</div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>

      <mat-list>
        <div mat-subheader>Laps Done</div>
        <mat-list-item>
          <mat-icon mat-list-icon>sports_score</mat-icon>
          <div mat-line>{{ routeRecord.lapsData.laps.length }}</div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>

      <mat-list>
        <div mat-subheader>Out Of Route</div>
        <mat-list-item>
          <mat-icon mat-list-icon>bus_alert</mat-icon>
          <div mat-line>{{ routeRecord.outOfRouteWayPoints.length }}</div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
    </div>
    <div
      id="map-wrapper-historico"
      fxFlex="100%"
      class="mat-elevation-z8"
    ></div>
  </div>
</div>
