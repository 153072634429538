import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PublicityService extends BaseService {
  constructor(private http_: HttpClient) {
    super(http_);
    this.url = 'publicity';
  }
}
