import { Component, Input, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {

  @Input() sidenav: boolean = false;
  @Input() drawer: MatDrawer;
  selectedBtn: string = '';
  user: any = {
    isAdmin: false,
    permissions: []
  }
  showAutobuses: boolean = false;
  showRutas: boolean = false;
  showHistorial: boolean = false;
  showDispositivos: boolean = false;
  showPublicidad: boolean = false;
  showReportes: boolean = false;
  showChecador: boolean = false;
  
  constructor(private router: Router, private sessionService: SessionService) { }

  ngOnInit(): void {
    this.user = this.sessionService.currentUser;
    this.loadMenu();
    let path = window.location.pathname.substring(1);
    if (path.length)
      path = path.charAt(0).toUpperCase() + path.slice(1);
    this.selectedBtn = path;
  }

  loadMenu() {
    this.showAutobuses = this.user.permissions.filter(p => p.allowed && p.permission.path == '/autobuses').length > 0;
    this.showRutas = this.user.permissions.filter(p => p.allowed && p.permission.path == '/rutas').length > 0;
    this.showHistorial = this.user.permissions.filter(p => p.allowed && p.permission.path == '/historial').length > 0;
    this.showDispositivos = this.user.permissions.filter(p => p.allowed && p.permission.path == '/dispositivos').length > 0;
    this.showPublicidad = this.user.permissions.filter(p => p.allowed && p.permission.path == '/publicidad').length > 0;
    this.showReportes = this.user.permissions.filter(p => p.allowed && p.permission.path == '/reportes').length > 0;
    this.showChecador = true;
  }

  selectThisBtn(btnVal: string) {
    if (this.drawer != null && this.drawer.close)
      this.drawer.close();
    this.selectedBtn = btnVal;
    this.router.navigateByUrl(btnVal.toLocaleLowerCase());
  }

}
