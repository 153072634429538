import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FileInputComponent } from "src/app/components/file-input/file-input.component";
import { LookupData } from "src/app/components/lookup/LookupData";
import { LoaderService } from "src/app/services/loader.service";
import { NotificationService } from "src/app/services/notification.service";
import { PublicityService } from "src/app/services/publicity.service";
import { ReportService } from "src/app/services/report.service";
import { RouteService } from "src/app/services/route.service";
import { SessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-publicity-form',
  templateUrl: './publicity-form.component.html',
  styleUrls: ['./publicity-form.component.scss']
})
export class PublicityFormComponent implements OnInit {

  @ViewChild('firstInput') firstInput: ElementRef;
  @ViewChild(FileInputComponent) fileInput: FileInputComponent;
  @Output() closedForm = new EventEmitter<any>();
  @Output() dataSubmitted = new EventEmitter<any>();
  routeLookupData: LookupData;

  imagesURL: string = environment.serverUrl;
  readOnly: boolean = true;

  form: FormGroup = new FormGroup({
    _id: new FormControl({ value: '', disabled: this.readOnly }),
    title: new FormControl({ value: '', disabled: this.readOnly }, [Validators.required, Validators.minLength(5)]),
    description: new FormControl({ value: '', disabled: this.readOnly }, [Validators.required]),
    allRoutes: new FormControl({ value: false, disabled: this.readOnly }),
    active: new FormControl({ value: false, disabled: this.readOnly })
  });
  routes: Array<any> = [];
  activity: Array<any> = [];
  imgName: string = null;
  date: Date = null;
  file: any = null;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private loader: LoaderService,
    private notification: NotificationService,
    private publicityService: PublicityService,
    private sessionService: SessionService,
    private routeService: RouteService,
    @Optional() private dialogRef: MatDialogRef<PublicityFormComponent>
  ) { }

  ngOnInit() {
    this.initializeLookup();
    setTimeout(() => {
      this.clearForm();
    }, 200);
  }

  initializeLookup() {
    this.routeLookupData = new LookupData({
      valueKey: '_id',
      descriptionKey: 'name',
      label: 'Asignar Ruta',
      placeHolder: 'Agregar Ruta',
      service: RouteService
    });
  }

  removeRoute(route) {
    const index = this.routes.indexOf(route);

    if (index >= 0) {
      this.routes.splice(index, 1);
    }
  }

  routeValueChanged(newRoute: any) {
    this.routeService.find({ _id: newRoute }).then(route => {
      this.routes.push(route[0]);
    }).catch(err => {
      console.log(err);
    });
    this.routeLookupData.description = "";
  }

  populateForm(publicity: any) {
    this.clearForm();
    this.form.patchValue(publicity);
    this.routes = publicity.routes || [];
    this.activity = publicity.activity;
    this.imgName = publicity.imgName;
  }

  clearForm() {
    this.form.reset();
    this.form.setValue({
      _id: null,
      title: '',
      description: null,
      allRoutes: false,
      active: false
    });
    this.activity = [];
    this.routes = [];
    this.imgName = null;
    this.date = null;
    this.fileInput.clearData();
    this.file = null;
  }

  submit() {
    if (!this.form.valid)
      return this.notification.showWarn("Formulario invalido", "Ok", 3000);
    if (this.file == null && this.form.value._id == null)
      return this.notification.showWarn("Es necesario cargar una imagen", "Ok", 3000);

    let postBody = this.getBody();
    this.loader.showSpinner();
    if (this.form.get('_id').value == "" || this.form.get('_id').value == null) {
      this.publicityService.post(postBody).then((createdPublicity) => {
        this.dataSubmitted.emit(true);
        this.clearForm();
        this.populateForm(createdPublicity);
      }).catch(err => {
        this.notification.showWarn(err.message || "Ocurrio un error al generar registro");
      }).finally(() => this.loader.hideSpinner());
    }
    else {
      this.publicityService.put(postBody).then((updatedPublicity) => {
        this.dataSubmitted.emit(true);
        this.clearForm();
        this.populateForm(updatedPublicity);
      }).catch(err => {
        this.notification.showWarn(err.message || "Ocurrio un error al guardar cambios en el registro");
      }).finally(() => this.loader.hideSpinner());
    }
  }

  getBody(): FormData {
    let form = this.form.value;
    let body = new FormData();
    if (this.file != null)
      body.append('img', this.file, this.file.name);

    body.append('id', form._id);
    body.append('title', form.title);
    body.append('description', form.description);
    body.append('imgName', this.imgName);
    body.append('allRoutes', form.allRoutes);
    this.routes.forEach((r) => {
      body.append('routes', r._id);
    });
    body.append('active', form.active);
    body.append('user', this.sessionService.currentUser._id);

    return body;
  }

  enableForm() {
    this.readOnly = false;
    this.form.enable();
    this.firstInput.nativeElement.focus();
  }

  disableForm() {
    this.readOnly = true;
  }

  close() {
    let closingData = Object.assign(this.form.value, this.form.value);

    if (this.dialogRef != null)
      this.dialogRef.close(closingData);

    this.closedForm.emit(true);
    this.clearForm();
  }

  imgChanged(data) {
    console.log(data);
    if (data.file == null)
      this.file == null
    else
      this.file = data.file;
  }
}