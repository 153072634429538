import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-route-history',
  templateUrl: './route-history.component.html',
  styleUrls: ['./route-history.component.scss']
})
export class RouteHistoryComponent implements OnInit {

  _routeHistoryData: MatTableDataSource<any>;
  @Input() set routeHistoryData(value: Array<any>) {
    this._routeHistoryData = new MatTableDataSource(value);
    this._routeHistoryData.sort = this.sort;
    this._routeHistoryData.paginator = this.paginator;
  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['route', 'user', 'time'];
  private searchValue: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  applyFilter() {
    this._routeHistoryData.filter = this.searchValue.trim().toLowerCase();
  }

}
