<div class="dialog-container">
  <mat-card>
    <mat-toolbar style="border-radius: 5px">
      <h2><mat-icon>search</mat-icon> Select {{ objectData.label }}</h2>
      <span class="fill-remaining-space"></span>
      <button
        class="btn-dialog-close"
        mat-button
        (click)="close()"
        tabIndex="-1"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </mat-toolbar>

    <br />

    <div fxLayout="row">
      <mat-form-field fxFlex="100%">
        <button
          mat-button
          matPrefix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <input
          matInput
          [(ngModel)]="searchValue"
          placeholder="Search..."
          autocomplete="off"
          type="text"
          (keyup)="applyFilter()"
        />
      </mat-form-field>
    </div>
  </mat-card>

  <br />

  <div class="mat-elevation-z8">
    <div class="inner">
      <mat-table [dataSource]="records" matSort>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              color="primary"
              (click)="selectRecord(element)"
            >
              <mat-icon>get_app</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Nombre</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.description
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="noData">
          <mat-footer-cell *matFooterCellDef colspan="6">
            <div class="full-width text-center">No records...</div>
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

        <mat-footer-row
          *matFooterRowDef="['noData']"
          [ngClass]="{ hidden: records != null && records.data.length > 0 }"
        >
        </mat-footer-row>
      </mat-table>
    </div>
    <mat-paginator
      [pageSizeOptions]="[15, 30, 50, 100]"
      [pageSize]="15"
      showFirstLastButton
    ></mat-paginator>
  </div>

  <br />

  <div class="padding-top">
    <mat-divider></mat-divider>
  </div>

  <br />

  <mat-card>
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutGap="1%"
      fxLayoutAlign="end"
    >
      <!-- <button type="button" (click)="selectRecord()" fxFlex="20%" fxFlex.xs="100%" mat-raised-button
        color="primary">Seleccionar</button> -->

      <button
        type="button"
        (click)="unSelectRecord()"
        fxFlex="20%"
        mat-raised-button
        fxFlex.xs="100%"
        color="warn"
      >
        Limpiar
      </button>
    </div>
  </mat-card>
</div>
