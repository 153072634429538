import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService extends BaseService {

  isLoggedIn: boolean = false;
  redirectUrl: string = "";
  currentUser: any;

  constructor(
    private http_: HttpClient,
    private router: Router
  ) {
    super(http_);
    this.url = "security";
  }

  /** Sends a login request to the server, gets the user configuration and creates a session if the login data is valid */
  logIn(data: any, options?: any): Promise<any> {

    return new Promise((resolve, reject) => {

      this.post(data).then(user => {

        if (user == null) {
          this.logOut();
          reject('Usuario no encontrado');
          return;
        }
        if (!user.active) {
          this.logOut();
          reject('El usuario no esta activo');
          return;
        }

        this.currentUser = user;
        localStorage.setItem("currentUser", JSON.stringify(this.currentUser));
        this.isLoggedIn = true;

        if (options && options.redirect)
          this.router.navigateByUrl('/');

        resolve(true);

      }).catch((err) => {
        reject(err)
        // this.logOut();
      });
    });
  }

  logOut(): void {
    localStorage.setItem("currentUser", null);
    this.isLoggedIn = false;
    this.currentUser = null;
    this.router.navigateByUrl('/login');
  }

  recoverSession(): boolean {
    var prevSessionUser = JSON.parse(localStorage.getItem("currentUser"));
    if (prevSessionUser == null)
      return false;

    this.currentUser = prevSessionUser;
    this.isLoggedIn = true;
    return true;
  }

  getDBBackup() {
    return new Promise((resolve, reject) => {
      this.http_.get(`${environment.serverUrl}${this.url}/dbBackup`, {
        responseType: "blob",
        headers: new HttpHeaders().append("Content-Type", "application/x-tar")
      }).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

}
