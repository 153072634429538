import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionService } from 'src/app/services/permission.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-security',
  templateUrl: './user-security.component.html',
  styleUrls: ['./user-security.component.scss']
})
export class UserSecurityComponent implements OnInit {

  @Input() set userPermissions(value: Array<any>) {
    this.data = value;
    this._userPermissions = new MatTableDataSource(value);
    this._userPermissions.sort = this.sort;
    this._userPermissions.paginator = this.paginator;
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: Array<any> = [];
  _userPermissions: MatTableDataSource<any>;
  displayedColumns: string[] = ['description', 'allowed'];
  constructor() { }

  ngOnInit(): void {
  }

  changed(elem: any) {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].permission._id == elem.permission._id) {
        this.data[i].allowed = !this.data[i].allowed;
        break;
      }
    }
  }

  getPermissions() {
    return this.data.map(up => {
      return {
        permission: up.permission._id,
        allowed: up.allowed
      };
    });
  }
}
