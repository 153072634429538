import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ThemeService {


  private theme = new BehaviorSubject<string>('light-theme');
  events = this.theme.asObservable();

  constructor() { }

  changeTheme(theme: string) {
    this.theme.next(theme);
  }
}