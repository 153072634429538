
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { ConcatArrayPipe } from '../pipes/concat-array.pipe';

@NgModule({
  declarations: [
    DateFormatPipe,
    ConcatArrayPipe
  ],
  imports: [CommonModule],
  exports: [
    DateFormatPipe,
    ConcatArrayPipe
  ]
})
export class PipesModule { }
