<div class="page-container">
  <mat-card *ngIf="showForm">
    <app-device-form
      (closedForm)="closeForm()"
      (dataSubmitted)="getDevices()"
    ></app-device-form>
    <br />
  </mat-card>

  <div class="search-div" fxLayout="row" fxLayoutGap="5px">
    <mat-form-field class="search-form-field">
      <button
        mat-button
        matPrefix
        mat-icon-button
        aria-label="Borrar"
        (click)="clearSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <input
        matInput
        [(ngModel)]="searchValue"
        placeholder="Search..."
        autocomplete="off"
        type="text"
        (keyup)="applyFilter()"
      />
    </mat-form-field>
    <button
      class="top-right-button"
      color="primary"
      mat-raised-button
      fxFlex="80px"
      (click)="newRecord()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <div class="mat-elevation-z8">
    <mat-table [dataSource]="devices" matSort>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button color="accent" (click)="edit(element)">
            <mat-icon>launch</mat-icon>
          </button>
          <button
            disabled
            mat-icon-button
            color="warn"
            (click)="delete(element._id)"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="deviceId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.deviceId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >SIM Number</mat-header-cell
        >
        <mat-cell *matCellDef="let element"
          >{{
            element.phoneNumber != null ? element.phoneNumber : "No Asignado"
          }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Description</mat-header-cell
        >
        <mat-cell *matCellDef="let element"
          >{{ element.description != null ? element.description : "" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Active</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.active" color="primary">check_box</mat-icon>
          <mat-icon *ngIf="!element.active">check_box_outline_blank</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="noData">
        <mat-footer-cell *matFooterCellDef colspan="6">
          <div class="full-width text-center">No Records...</div>
        </mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      <mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{ hidden: devices != null && devices.data.length > 0 }"
      >
      </mat-footer-row>
    </mat-table>
    <mat-paginator
      [pageSizeOptions]="[15, 30, 50, 100]"
      [pageSize]="15"
      showFirstLastButton
    ></mat-paginator>
  </div>
</div>
