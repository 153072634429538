import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class DateFormatPipe implements PipeTransform {

  transform(date: any, fullDate?: boolean): any {
    switch (typeof date) {
      case "string":
        date = new Date(date);
        return this.formatDate(date, fullDate);
      case "object":
        if (typeof date.getMonth == "function") {
          return this.formatDate(date, fullDate);
        } else
          return date;
      case "number":
        return date;
      default:
        return date;
    }
  }

  private formatDate(date: Date, fullDate?: boolean) {
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    month = (Number(month) > 9) ? month : "0" + month;
    let day = date.getDate().toString();
    day = (Number(day) > 9) ? day : "0" + day;
    let hour = date.getHours().toString();
    hour = (Number(hour) > 9) ? hour : "0" + hour;
    let minute = date.getMinutes().toString();
    minute = (Number(minute) > 9) ? minute : "0" + minute;

    if (fullDate)
      return year + "/" + month + "/" + day + " " + hour + ":" + minute;
    else
      return hour + ":" + minute;
  }

}
