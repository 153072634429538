<div class="mat-elevation-z8">
  <div class="text-center padding-top">
    <h4 style="font: 500 18px/28px Roboto, 'Helvetica Neue', sans-serif">
      <mat-icon class="fixed-icon">share_location</mat-icon>
      GPS Device Assignment History
    </h4>
  </div>

  <mat-table matSort [dataSource]="_deviceHistoryData">
    <ng-container matColumnDef="device">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Device Code</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{
        element.device ? element.device.deviceId : ""
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Assigned By</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{
        element.user ? element.user.name : ""
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="time">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</mat-header-cell>
      <mat-cell *matCellDef="let element">{{
        element.date | formatDate : true
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6">
        <div class="full-width text-center">
          No GPS Device Has Been Assigned...
        </div>
      </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    <mat-footer-row
      *matFooterRowDef="['noData']"
      [ngClass]="{
        hidden: _deviceHistoryData != null && _deviceHistoryData.data.length > 0
      }"
    >
    </mat-footer-row>
  </mat-table>
  <mat-paginator
    [pageSizeOptions]="[5, 20, 50, 100]"
    [pageSize]="5"
    showFirstLastButton
  ></mat-paginator>
</div>
