<div id="app-component-container">
  <div id="loader" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
    <div class="spinner-text">Cargando...</div>
  </div>

  <div *ngIf="sessionService.isLoggedIn">
    <mat-toolbar *ngIf="!isMobile">
      <span class="cursor-pointer" routerLink="/">KITGPS</span>
      <app-navigation-menu></app-navigation-menu>
      <span class="toolbar-spacer"></span>
      <app-theme-picker></app-theme-picker>
      <button
        (click)="logout()"
        mat-icon-button
        type="button"
        aria-label="Cerrar Sesión"
      >
        <mat-icon class="toolbar-icon">exit_to_app</mat-icon>
      </button>
    </mat-toolbar>

    <mat-toolbar *ngIf="isMobile">
      <span class="cursor-pointer" routerLink="/">KITGPS</span>
      <span class="toolbar-spacer"></span>
      <button mat-icon-button (click)="toggleMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div *ngIf="!isMobile" id="main-content">
    <router-outlet></router-outlet>
  </div>

  <div *ngIf="isMobile" id="main-content">
    <mat-drawer-container autosize *ngIf="isMobile" [hasBackdrop]="true">
      <mat-drawer #drawer [mode]="'over'">
        <app-navigation-menu
          [sidenav]="true"
          [drawer]="drawer"
          *ngIf="sessionService.isLoggedIn"
        ></app-navigation-menu>

        <div class="sidenav-logout">
          <button
            class="logout"
            (click)="logout()"
            mat-icon-button
            type="button"
            aria-label="Cerrar Sesión"
          >
            <mat-icon class="toolbar-icon">exit_to_app</mat-icon> Cerrar Sesión
          </button>
        </div>
      </mat-drawer>
      <mat-drawer-content>
        <router-outlet></router-outlet>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
