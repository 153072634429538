import { Component, OnInit, } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as MapBoxGL from 'mapbox-gl';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RouteService } from 'src/app/services/route.service';
import { LookupData } from 'src/app/components/lookup/LookupData';
import { BusService } from 'src/app/services/bus.service';
import { Subscription } from 'rxjs';
import { MapService } from 'src/app/services/map.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'app-routes-page',
  templateUrl: './checker-page.component.html',
  styleUrls: ['./checker-page.component.scss']
})
export class CheckerPageComponent implements OnInit {
  map: MapBoxGL.Map;
  routeLookupData: LookupData;
  activeFilter: string = "";
  busMarkers: Map<string, MapBoxGL.Marker> = new Map<string, MapBoxGL.Marker>();
  allBusesData: Array<any> = [];
  socketRouteSubscription: Subscription;
  selectedRoute: any = { name: '' };

  constructor(
    private routeService: RouteService,
    private webSocket: WebSocketService,
    private loader: LoaderService,
    private notificationService: NotificationService,
    private notification: NotificationService,
    private mapService: MapService,
    private busService: BusService
  ) { }

  ngOnInit(): void {
    this.initializeSockets();
    this.initializeMap();
    this.initializeLookups();
    this.getBusesData();
  }
  
  initializeSockets() {
    this.webSocket.connect();
  }

  initializeMap() {
    (MapBoxGL as any).accessToken = environment.mapBoxKey;

    this.map = new MapBoxGL.Map({
      container: 'map-wrapper-checker', // container id
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [environment.mapCenter.lng, environment.mapCenter.lat], // starting position
      zoom: 14.2 // starting zoom
    });
  }
  
  initializeLookups() {
    this.routeLookupData = new LookupData({
      valueKey: '_id',
      descriptionKey: 'name',
      label: 'Filtrar Por Ruta',
      placeHolder: 'Filtrar Por Ruta',
      service: RouteService
    });
  }
  
  getBusesData() {
    this.busService.get().then(data => {
      this.allBusesData = data || [];
    }).catch(err => {
      console.log(err);
    })
  }

  routeChanged(routeId) {
    // this.routeLookupData.value = null;
    // this.routeLookupData.description = "";
    this.activeFilter = "route";
    this.subscribeToRoute(routeId);
  }
  
  subscribeToRoute(routeId) {
    debugger;
    this.routeService.find({ _id: routeId }).then(data => {
      if (data == null || !data.length)
        throw 'Invalid data';

      this.busService.find({ route: routeId }).then(assignedBuses => {
        if (!assignedBuses || !assignedBuses.length)
          this.notificationService.showConfirmation("Esta ruta no tiene autobuses asignados");
      }).catch(err => {
        this.notificationService.showConfirmation("Esta ruta no tiene autobuses asignados");
      });
      this.clearMapData();
      this.setSelectedRoute(data[0]);
    }).catch(err => {
      this.notificationService.showWarn('Error al cargar ruta');
      console.error(err);
    });

    this.webSocket.emit('quitRoom', { room: 'all' }, () => {
      debugger;
      this.endSubscriptions();
      this.socketRouteSubscription = this.webSocket.listen('route').subscribe((data) => this.manageRouteData(data));
      this.webSocket.emit('subscribeToRoute', { routeId: routeId });
    });
  }
  
  clearMapData() {
    this.mapService.clearMap(this.map, Array.from(this.busMarkers?.values() || []).concat(this.selectedRoute?.parsedWayPoints || []));
    this.busMarkers = new Map<string, MapBoxGL.Marker>();
    this.selectedRoute = null;
  }

  endSubscriptions() {
    if (this.socketRouteSubscription && typeof this.socketRouteSubscription.unsubscribe == "function")
      this.socketRouteSubscription.unsubscribe();
  }
  
  setSelectedRoute(route: any) {
    if (route == null)
      return;
    if (typeof route == "string") {
      this.routeService.find({ _id: route }).then(data => {
        if (data == null || !data.length)
          throw 'Invalid data';
        this.setSelectedRoute(data[0]);
      }).catch(err => {
        console.error(err);
      });
    } else if (typeof route == "object") {
      this.selectedRoute = route;
      this.selectedRoute.parsedWayPoints = this.mapService.loadRouteToMap(this.map, this.selectedRoute.wayPoints);
      this.mapService.drawRoute(this.map, this.selectedRoute.parsedWayPoints);
      this.mapService.centerMap(this.map, this.selectedRoute.parsedWayPoints);
    }
  }
  
  manageRouteData(data) {
    if (!data.bus)
      return null;

    let busMarker = this.busMarkers.get(data.bus);
    data.number = this.allBusesData.filter(b => b._id == data.bus)[0]?.number;

    let busData = this.allBusesData.filter(b => b._id == data.bus)[0];
    if (busData && busData.route)
      data.routeName = busData.route.name;

    let popupText = this.mapService.getBusPopupText(data);
    if (busMarker == null) {
      let busPopup = new MapBoxGL.Popup({ closeOnClick: false, closeButton: false })
        .setOffset([0, -20]).setHTML(popupText);
      let newMarker = this.mapService.addSingleMarker(this.map, [data.lng, data.lat]);
      newMarker.setPopup(busPopup);
      newMarker.togglePopup();
      this.busMarkers.set(data.bus, newMarker);
    }
    else {
      busMarker.getPopup().setHTML(popupText);
      this.mapService.animateMarkerMovement(busMarker, new MapBoxGL.LngLat(data.lng, data.lat), 1000);
    }
  }

}
